export default function LoadingButton({ style, ...rest }) {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#eee',
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        ...style,
      }}
      {...rest}
    >
      <img
        className='h-10 w-10 text-grey-500'
        src={`${window.storeConfigInitial?.storageBaseUrl}/assets/next-loading.svg`}
        alt=''
      />
    </div>
  );
}
