import getImage from 'helpers/getImage';
import getPriceForDisplay from 'helpers/getPriceForDisplay';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useGetHalfItems({
  halfItem,
  hhActiveMenuSizes,
  itemVariants,
  menuItems,
}) {
  const {
    soldOutMenuItems,
    currentOrder,
    specialFilter: { isSpecialFilterApplied, spMenuSizes },
  } = useSelector((state) => state.homeReducer);

  const halfItemIds = useMemo(
    () => halfItem.menuItems.filter((i) => i.isActive).map((i) => i._id),
    [halfItem.menuItems]
  );

  const defaultPairId =
    hhActiveMenuSizes && hhActiveMenuSizes[0]?.pairId
      ? hhActiveMenuSizes[0].pairId
      : halfItem.defaultSizes[0].pairId;

  const sizeIds = useMemo(
    () =>
      [
        ...halfItem.selectedSizes.filter(
          ({ pairId }) => pairId === defaultPairId
        ),
        ...halfItem.selectedModifiers.filter(
          ({ pairId }) => pairId === defaultPairId
        ),
      ].map((i) => i._id),
    [defaultPairId, halfItem.selectedModifiers, halfItem.selectedSizes]
  );

  const variantIds = useMemo(
    () => itemVariants.map((i) => i._id),
    [itemVariants]
  );

  let items = useMemo(
    () =>
      (menuItems ?? [])
        .filter((i) => {
          const halfPredicate = halfItemIds.includes(i._id);
          const { itemType, selectedSizes, selectedModifiers } = i;
          const size =
            itemType === 0
              ? selectedSizes?.find((s) => sizeIds.includes(s._id))
              : selectedModifiers
                  ?.flatMap((m) =>
                    m.subModifiers.filter((sm) => sm.isAvailable)
                  )
                  .find((i) => sizeIds.includes(i._id));

          const variantsPredicate =
            variantIds.length > 0 && size && Array.isArray(size.variants)
              ? size.variants.some(
                  (v) =>
                    v.isSelected && v.isAvailable && variantIds.includes(v._id)
                )
              : true;

          const isAvailable =
            i.orderFromKeys?.includes('1') &&
            i.orderTypeKeys?.includes(currentOrder?.orderType) &&
            i.isPublished;

          return halfPredicate && !!size && variantsPredicate && isAvailable;
        })
        .map((menuItem) => ({
          ...menuItem,
          unitPrice: getPriceForDisplay(
            menuItem,
            isSpecialFilterApplied,
            soldOutMenuItems,
            spMenuSizes,
            {
              orderType: currentOrder?.orderType,
            }
          ),
          itemObjType: menuItem.itemType,
          urlS3: getImage(menuItem.mediaPath || menuItem.urlS3 || menuItem.url),
        })),
    [
      halfItemIds,
      menuItems,
      sizeIds,
      variantIds,
      isSpecialFilterApplied,
      soldOutMenuItems,
      spMenuSizes,
      currentOrder?.orderType,
    ]
  );

  // create categoryId as key and all the other data in the array object
  const itemsMap = items?.reduce((acc, item) => {
    acc[item?.categoryId] = acc[item?.categoryId] || [];
    acc[item?.categoryId].push(item);
    return acc;
  }, {});

  // now sort the itemsMap each key's data by displayOrder key
  Object.keys(itemsMap)?.forEach((key) => {
    itemsMap[key].sort((a, b) => a?.displayOrder - b?.displayOrder);
  });

  // now merge them back into a single array
  items = Object.keys(itemsMap)?.reduce((acc, key) => {
    acc.push(...itemsMap[key]);
    return acc;
  }, []);

  return items;
}
