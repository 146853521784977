import {
  CustomerCategoryTitleUI,
  GridUI,
} from '@next-order/next-order-components';
import HalfHalfOrSpecialMenuItemsViewContainer from 'components/NewMenuItem/HalfHalfOrSpecialMenuItemsViewContainer';
import {
  CATEGORY_DISPLAY_TYPE,
  MENU_ITEM_OBJ_TYPE,
  SOLD_OUT_ITEM_PRICE,
} from 'helpers/enums';
import getMenuItemIngredients from 'helpers/getMenuItemIngredients';
import getPriceForDisplay from 'helpers/getPriceForDisplay';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SearchModal from './SearchModal';
import MenuItemsLoadingSkeleton from './MenuItemsLoadingSkeleton';
import getImage from 'helpers/getImage';

export default function RenderVoucherFilterAppliedMenuItems({
  filteredCategories,
  halfHalfSetup,
  onClickItemHandler,
  currency,
}) {
  const {
    soldOutMenuItems,
    voucherFilter: {
      isVoucherFilterApplied,
      voucherMenuSizes,
      voucherBoxIndex,
    },
    currentOrder,
  } = useSelector((state) => state.homeReducer);

  const categoriesList = useMemo(() => {
    return filteredCategories?.map((filterSPCat) => {
      let data = filterSPCat?.filteredMenuItems?.map((menuItem) => ({
        ...menuItem,
        itemObjType: menuItem.itemType,
        unitPrice: getPriceForDisplay(
          menuItem,
          isVoucherFilterApplied,
          soldOutMenuItems,
          voucherMenuSizes,
          { orderType: currentOrder?.orderType }
        ),
      }));

      return {
        data,
        displayType: CATEGORY_DISPLAY_TYPE.GRID,
        name: filterSPCat.name,
        _id: filterSPCat._id,
      };
    });
  }, [
    currentOrder?.orderType,
    filteredCategories,
    voucherMenuSizes,
    isVoucherFilterApplied,
    soldOutMenuItems,
  ]);

  const searchCategoriesList = useMemo(() => {
    return (categoriesList ?? [])
      .map((category) => ({
        name: category?.name,
        data: (category?.data ?? []).filter(
          (item) => item.itemPrice !== SOLD_OUT_ITEM_PRICE
        ),
      }))
      ?.filter((category) => category?.data?.length > 0);
  }, [categoriesList]);

  return (
    <>
      {categoriesList?.length > 0 && (
        <>
          <CustomerCategoryTitleUI>
            Select voucher item {voucherBoxIndex + 1}
          </CustomerCategoryTitleUI>

          <GridUI align='start' gap='none' colsTemplate={`cw-grid`}>
            {categoriesList?.map((category) =>
              category?.data?.map((menuItem) => (
                <HalfHalfOrSpecialMenuItemsViewContainer
                  key={`sp-${menuItem._id}`}
                  productName={menuItem.name}
                  bgImgSrc={getImage(
                    menuItem.mediaPath || menuItem.urlS3 || menuItem.url
                  )}
                  ingredients={getMenuItemIngredients(menuItem.description)}
                  menuItem={menuItem}
                  onClickItemHandler={onClickItemHandler}
                  currency={currency}
                />
              ))
            )}
          </GridUI>
        </>
      )}
      {categoriesList.length <= 0 && (
        <MenuItemsLoadingSkeleton noOfItems={12} />
      )}
      <SearchModal
        searchCategoriesList={searchCategoriesList}
        onClickItemHandler={onClickItemHandler}
      />
    </>
  );
}
