const getGroupedModifiers = (originalModifiers) => {
  const modifiers = JSON.parse(JSON.stringify(originalModifiers));
  const subModifierNameLookup = new Map();

  for (const modifier of modifiers) {
    if (!modifier.subModifiers || modifier.subModifiers.length === 0) continue;

    const subModifierNames = modifier.subModifiers
      .map((sm) => sm.name)
      .sort()
      .join('|');

    if (!subModifierNameLookup.has(subModifierNames)) {
      subModifierNameLookup.set(subModifierNames, []);
    }
    subModifierNameLookup.get(subModifierNames).push(modifier);
  }

  const groups = {};

  // eslint-disable-next-line no-unused-vars
  for (const [_, modifiersInGroup] of subModifierNameLookup.entries()) {
    if (modifiersInGroup.length > 1) {
      const baseId = modifiersInGroup[0]._id;
      const associatedIds = modifiersInGroup.slice(1).map((m) => m._id);

      groups[baseId] = associatedIds;
    }
  }

  const lookupTable = new Map();

  for (const [baseId, associatedIds] of Object.entries(groups)) {
    lookupTable.set(baseId, { isBase: true });
    associatedIds.forEach((id) => {
      lookupTable.set(id, { isBase: false });
    });
  }

  modifiers.forEach((modifier) => {
    const groupInfo = lookupTable.get(modifier._id);

    if (groupInfo) {
      modifier.isMultiselect = true;
      if (!groupInfo.isBase) {
        modifier.isHiddenMultiselect = true;
      }
      modifier.subModifiers.forEach((subModifier) => {
        if (subModifier.name.toLowerCase()?.split(' ').includes('no')) {
          subModifier.quantity = 1;
          subModifier.isHiddenMultiselect = true;
        }
      });
    }
  });

  return {
    groupedModifiers: modifiers,
    groupsMultiModifiers: Object.keys(groups).length > 0 ? groups : null,
  };
};

export default getGroupedModifiers;
