const getCardBrandFun = (cardType) => {
  if (cardType === 0) return 'Unknown';
  else if (cardType === 1) return 'JCB';
  else if (cardType === 2) return 'American Express(Amex)';
  else if (cardType === 3) return 'Diners Club';
  else if (cardType === 4) return 'Discover';
  else if (cardType === 5) return 'MasterCard';
  else if (cardType === 6) return 'Visa';
  else if (cardType === 7) return 'UnionPay';
  else return 'Unknown';
};
const getCardTypeFun = (brand) => {
  if (brand.toLowerCase() === 'jcb') {
    return { type: '1', name: 'JCB' };
  } else if (
    brand.toLowerCase() === 'american express' ||
    brand.toLowerCase().includes('american') ||
    brand.toLowerCase().includes('express') ||
    brand.toLowerCase().includes('amex')
  ) {
    return { type: '2', name: 'American Express(Amex)' };
  } else if (brand.toLowerCase() === 'diners club') {
    return { type: '3', name: 'Diners Club' };
  } else if (brand.toLowerCase() === 'discover') {
    return { type: '4', name: 'Discover' };
  } else if (brand.toLowerCase() === 'mastercard') {
    return { type: '5', name: 'MasterCard' };
  } else if (brand.toLowerCase() === 'visa') {
    return { type: '6', name: 'Visa' };
  } else if (brand.toLowerCase() === 'unionpay') {
    return { type: '7', name: 'UnionPay' };
  } else {
    return { type: '0', name: 'Unknown' };
  }
};

const getCardUrlFun = (cardType) => {
  if (cardType === '6')
    return `${window.storeConfigInitial?.storageBaseUrl}/assets/visasvg.svg`;
  else if (cardType === '5')
    return `${window.storeConfigInitial?.storageBaseUrl}/assets/mastercardsvg.svg`;
  else if (cardType === '2')
    return `${window.storeConfigInitial?.storageBaseUrl}/assets/amexsvg.svg`;
  else
    return `${window.storeConfigInitial?.storageBaseUrl}/assets/credit-card-2.svg`;
};

export const getCardBrand = getCardBrandFun;
export const getCardType = getCardTypeFun;
export const getCardUrl = getCardUrlFun;
