import PropTypes from 'prop-types';
import ItemView from '../../components/ItemView';
import getImage from 'helpers/getImage';

export default function VoucherOrderView({
  ingredientCategories,
  orderItem,
  closeOrder,
  itemInProgress,
  openedItemId,
  addToOrder,
  rightSideViewVisibilityIndex,
  toggleAnimation,
  sidebarAnimationClass,
}) {
  const image = getImage(orderItem?.mediaPath || orderItem?.urlS3);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      <ItemView
        key={String(toggleAnimation)}
        ingredientCategories={ingredientCategories}
        orderItem={orderItem}
        name={orderItem && orderItem.name ? orderItem.name : ''}
        imageUrl={image}
        description={
          orderItem && orderItem.description ? orderItem.description : ''
        }
        closeItem={closeOrder}
        itemInProgress={itemInProgress}
        openedItemId={openedItemId}
        addToOrder={addToOrder}
        rightSideViewVisibilityIndex={rightSideViewVisibilityIndex}
        openedFrom={'special'}
      />
    </div>
  );
}

VoucherOrderView.propTypes = {
  rightSideViewVisibilityIndex: PropTypes.any,
  sizeID: PropTypes.any,
  sizeName: PropTypes.any,
};
