import {
  CustomerWebsiteLayoutUI,
  SearchUI,
  TabsNavUI,
} from '@next-order/next-order-components';
import FloatingNavbar from 'components/Header/FloatingNavbar';
import {
  setState as setPropsState,
  toggleMobileCart,
} from 'containers/Home/actions';
import ReviewOrder from 'containers/ReviewOrder';
import { MenuContext } from 'contexts/MenuProvider';
import { BREAKPOINT_VALUES, ORDER_STAGE_TYPES } from 'helpers/enums';
import useMenuItems from 'helpers/hooks/useMenuItems';
import useSlider from 'helpers/hooks/useSlider';

import { createRef, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from '../../helpers/isMobile';
import RenderFilterAppliedSpecialMenuItems from './RenderFilterAppliedSpecialMenuItems';
import RenderHalfViewMenuItems from './RenderHalfViewMenuItems';
import RenderSimpleAndSpecialMenuItems from './RenderSimpleAndSpecialMenuItems';
import RenderVoucherFilterAppliedMenuItems from './RenderVoucherFilterAppliedMenuItems';
import getImage from 'helpers/getImage';

export default function NewMenuItems({
  halfView,
  halfItem,
  hhActiveMenuSizes,
  itemVariants,
  selectedHalfItems,
  getOrderBar,
  onClickItemHandler,
  updateOrderDeliveryAddress,
  saveUnit,
  signOut,
  hideOrder,
}) {
  const dispatch = useDispatch();
  const { selectedMenuItemsLength, breakPoint, setSearchModalOpen } =
    useContext(MenuContext);

  const {
    isOrderAgainAvailable,
    categoriesList,
    filteredCategoriesList,
    isSpecialFilterApplied,
    halfHalfSetup,
    spSubModifiers,

    menuItems,
    storeConfig,
    orderSetup,
    currentOrder,
    mobileCart,
    offers,
    currentUser,
    userDetails,
    isVoucherFilterApplied,
  } = useMenuItems({
    halfView,
    halfItem,
    hhActiveMenuSizes,
    itemVariants,
    selectedHalfItems,
  });
  const [activeTabID, setActiveTabID] = useState(isOrderAgainAvailable ? 0 : 1);
  const allTabs = useMemo(() => {
    return categoriesList
      ?.sort((a, b) => a.displayOrder - b.displayOrder)
      .map((category, id) => {
        let categoryId = id;
        if (!isOrderAgainAvailable) {
          categoryId = id + 1;
        }

        return {
          id: categoryId,
          title: category.name,
        };
      });
  }, [categoriesList, isOrderAgainAvailable]);

  const allRefs = useMemo(
    () => allTabs.map(() => createRef()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allTabs?.length]
  );

  const scrollToCategory = useSlider({
    allRefs,
    activeTabID,
    setActiveTabID,
    marginTop: -20,
    rootMargin: '-150px',
    isOrderAgainAvailable,
    orderStage: currentOrder?.stage,
  });

  // We should show all Menu Items if specials view of halfhalf view is not selected
  const showMenuItems =
    !isSpecialFilterApplied && !halfView && !isVoucherFilterApplied;
  const showReviewOrderScreen =
    currentOrder &&
    currentOrder.stage &&
    currentOrder.stage >= ORDER_STAGE_TYPES.REVIEW &&
    currentOrder.isAllowToConfirm;
  const showSignOutButton =
    (currentUser?.phoneNumber || userDetails?.mobileNumber) &&
    userDetails?.firstName &&
    currentOrder?.stage < ORDER_STAGE_TYPES.REVIEW;

  const showBookingButton =
    currentOrder &&
    currentOrder.stage < ORDER_STAGE_TYPES.REVIEW &&
    orderSetup?.isTableBookingOn;

  const showLoyaltyButton =
    currentOrder &&
    currentOrder.stage < ORDER_STAGE_TYPES.REVIEW &&
    offers &&
    offers.length > 0;

  // We only show back button if specials view is selected or halfhalf view is selected
  const showBackBtn =
    !showMenuItems && ((isSpecialFilterApplied && !halfView) || halfView);

  return (
    <>
      <CustomerWebsiteLayoutUI
        showBackBtn={showBackBtn}
        onClickBackBtn={hideOrder}
        openOrderBar={mobileCart} //  toggle order bar on screen smaller  1024px
        onOpenChangeOrderBar={() => dispatch(toggleMobileCart(true))}
        orderBarAnimation={'slideUp'}
        logoSrc={getImage(storeConfig.logoPath || storeConfig.logo)}
        logoAlt={storeConfig.name}
        orderBar={getOrderBar()}
        showViewOrderBtn={showMenuItems && selectedMenuItemsLength > 0}
        viewOrderBtnCount={selectedMenuItemsLength}
        viewOrderBtnText='View Order'
        onClickSearchBtn={setSearchModalOpen}
        extendedClassName={`${showReviewOrderScreen ? 'overflow-hidden ' : ''} 
        `}
        headerButtons={
          <HeaderButtons
            orderSetup={orderSetup}
            showSignOutButton={showSignOutButton}
            showBookingButton={showBookingButton}
            showLoyaltyButton={showLoyaltyButton}
            signOut={signOut}
          />
        }
        headerSearch={
          !showReviewOrderScreen ? (
            <SearchUI
              extendedClassName='max-w-60.75 w-full hidden cursor-pointer md:block'
              onClick={() => setSearchModalOpen(true)}
              placeholder='Search Menu'
              s=''
              onChange={(e) => null}
              autoComplete='off'
              styleType='customer-website'
            />
          ) : null
        }
        headerTabs={
          !showReviewOrderScreen && showMenuItems ? (
            <TabsNavUI
              activeTabID={activeTabID}
              extendedClassName={'-mx-5 !text-primary-title'}
              extendedBtnClassName='!pb-2 !mr-6 text-3.75 leading-5'
              extendedLineClassName='!h-[2.5px]'
              fontSize='custom'
              fontWeight='semibold'
              setActiveTabID={(_, id) => scrollToCategory(id)}
              tabsList={allTabs}
              showScrollBtn={!isMobile()}
            />
          ) : null
        }
      >
        {/* =====================>Menu Items List<====================== */}
        {showReviewOrderScreen ? (
          <ReviewOrder
            updateOrderDeliveryAddress={updateOrderDeliveryAddress}
            saveUnit={saveUnit}
          />
        ) : (
          <MenuItems
            categoriesList={categoriesList}
            allRefs={allRefs}
            filteredCategoriesList={filteredCategoriesList}
            halfHalfSetup={halfHalfSetup}
            halfItem={halfItem}
            hhActiveMenuSizes={hhActiveMenuSizes}
            itemVariants={itemVariants}
            menuItems={menuItems}
            onClickItemHandler={onClickItemHandler}
            spSubModifiers={spSubModifiers}
            storeConfig={storeConfig}
            showMenuItems={showMenuItems}
            isSpecialFilterApplied={isSpecialFilterApplied}
            halfView={halfView}
            breakPoint={breakPoint}
            stage={currentOrder.stage}
            selectedMenuItemsLength={selectedMenuItemsLength}
            isVoucherFilterApplied={isVoucherFilterApplied}
          />
        )}
        {/* =====================>Menu Items List<====================== */}
      </CustomerWebsiteLayoutUI>
    </>
  );
}
// =====================>Header Buttons<======================
const onClickDefaultState = {
  loyaltyOpen: false,
  aboutOpen: false,
  bookingOpen: false,
};
function HeaderButtons({
  orderSetup,
  showSignOutButton,
  showBookingButton,
  showLoyaltyButton,
  signOut,
}) {
  const dispatch = useDispatch();
  const onButtonClick = (key) => {
    dispatch(toggleMobileCart(true));
    dispatch(setPropsState({ ...onClickDefaultState, [key]: true }));
  };

  return (
    <>
      <CustomerWebsiteLayoutUI.HeaderButton
        onClick={() => onButtonClick('aboutOpen')}
      >
        About
      </CustomerWebsiteLayoutUI.HeaderButton>
      {showLoyaltyButton && (
        <CustomerWebsiteLayoutUI.HeaderButton
          onClick={() => onButtonClick('loyaltyOpen')}
        >
          Loyalty
        </CustomerWebsiteLayoutUI.HeaderButton>
      )}
      {showBookingButton && (
        <CustomerWebsiteLayoutUI.HeaderButton
          onClick={() => {
            if (
              orderSetup.useCustomTableBooking === true &&
              orderSetup.customBookingURL !== ''
            ) {
              window.open(orderSetup.customBookingURL, '_blank');
            } else {
              onButtonClick('bookingOpen');
            }
          }}
        >
          Booking
        </CustomerWebsiteLayoutUI.HeaderButton>
      )}

      {showSignOutButton && (
        <CustomerWebsiteLayoutUI.HeaderButton onClick={signOut}>
          Sign Out
        </CustomerWebsiteLayoutUI.HeaderButton>
      )}
    </>
  );
}

// =====================>Menu Items<======================
function MenuItems({
  categoriesList,
  allRefs,
  filteredCategoriesList,
  halfHalfSetup,
  halfItem,
  hhActiveMenuSizes,
  itemVariants,
  menuItems,
  onClickItemHandler,
  spSubModifiers,

  storeConfig,
  showMenuItems,
  isSpecialFilterApplied,
  isVoucherFilterApplied,
  halfView,
  breakPoint,
  stage,
  selectedMenuItemsLength,
}) {
  return (
    <>
      {showMenuItems ? (
        <RenderSimpleAndSpecialMenuItems
          categoriesList={categoriesList}
          allRefs={allRefs}
          currency={storeConfig.currency}
          onClickItemHandler={onClickItemHandler}
        />
      ) : isSpecialFilterApplied && !halfView ? (
        <RenderFilterAppliedSpecialMenuItems
          filteredCategories={filteredCategoriesList}
          halfHalfSetup={halfHalfSetup}
          onClickItemHandler={onClickItemHandler}
          spSubModifiers={spSubModifiers}
          currency={storeConfig.currency}
        />
      ) : isVoucherFilterApplied && !halfView ? (
        <RenderVoucherFilterAppliedMenuItems
          filteredCategories={filteredCategoriesList}
          halfHalfSetup={halfHalfSetup}
          onClickItemHandler={onClickItemHandler}
          spSubModifiers={spSubModifiers}
          currency={storeConfig.currency}
        />
      ) : halfView ? (
        <RenderHalfViewMenuItems
          halfItem={halfItem}
          hhActiveMenuSizes={hhActiveMenuSizes}
          itemVariants={itemVariants}
          menuItems={menuItems}
          onClickItemHandler={onClickItemHandler}
          currency={storeConfig.currency}
        />
      ) : null}

      {breakPoint <= BREAKPOINT_VALUES.DESKTOP &&
        selectedMenuItemsLength === 0 &&
        stage < ORDER_STAGE_TYPES.REVIEW &&
        showMenuItems && <FloatingNavbar />}
    </>
  );
}
