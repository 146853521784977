import { _firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';

import { getCloseDatesPromise } from './methods';
import {
  getUpdatedDataFromFile,
  getUpdatedRestaurantMenuUrls,
  getUpdatedStoreMenuUrls,
} from 'containers/Home/methods';

export function initOrder(storeId, restaurantId, timeZone) {
  return async function (dispatch) {
    const allShifts = window.StoreShifts || [];
    const allSuburbs = window.Suburbs || [];
    const closeDates = window.CloseDates || [];
    dispatch({
      type: ActionTypes.InitializeOrder,
      allShifts,
      allSuburbs,
      closeDates,
    });

    const { shiftsStorageUrl, suburbsStorageUrl } =
      await getUpdatedStoreMenuUrls(storeId);
    const { ingredientCategoriesStorageUrl } =
      await getUpdatedRestaurantMenuUrls(restaurantId);

    const ingredientCategories = await getUpdatedDataFromFile(
      'ingredientCategories',
      ingredientCategoriesStorageUrl
    );

    if (!allShifts?.length) {
      const storeShifts = await getUpdatedDataFromFile(
        'shifts',
        shiftsStorageUrl
      );
      dispatch({
        type: ActionTypes.InitializeOrder,
        allShifts: storeShifts,
      });
    }

    if (!allSuburbs?.length) {
      const storeSuburbs = await getUpdatedDataFromFile(
        'suburbs',
        suburbsStorageUrl
      );
      dispatch({
        type: ActionTypes.InitializeOrder,
        allSuburbs: storeSuburbs,
      });
    }
    if (!closeDates?.length) {
      const storeCloseDates = await getCloseDatesPromise(storeId, timeZone);
      dispatch({
        type: ActionTypes.InitializeOrder,
        closeDates: storeCloseDates,
      });
    }

    dispatch({
      type: ActionTypes.InitializeOrder,
      ingredientCategories,
    });
  };
}

export function addNotSupportedSuburbs(
  storeConfig,
  addressObj,
  suburb,
  username,
  mobileNumber,
  userId
) {
  let obj = {};
  obj.address = addressObj.description;
  obj.suburb = suburb;
  obj.storeId = storeConfig.storeId;
  obj.restaurantId = storeConfig.restaurantId;
  obj.date = getCurrentFormattedMomentDate(storeConfig.timeZone);
  obj.dateTimestamp = getCurrentTimestamp();
  obj.username = username;
  obj.mobileNumber = getFormattedMobileNumber(mobileNumber);
  obj.userId = userId;
  // firestore write
  _firestore
    .collection('NotSupportedSuburb')
    .add(obj)
    .then(() => {});
}
