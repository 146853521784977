import { selectStoreConfig } from 'containers/Layout/selectors';
import { CATEGORY_DISPLAY_TYPE, MENU_ITEM_DATA_TYPE } from 'helpers/enums';
import { getMinifiedMenuItemForInstore } from 'helpers/getMenuItemForInstore';
import getSimpleAndSpecialMenuItems from 'helpers/getSimpleAndSpecialMenuItems';
import { includes } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useGetPopulars from './useGetPopulars';
import useOrderAgain from './useOrderAgain';

const weekdays = {
  1: `Monday`,
  2: `Tuesday`,
  3: `Wednesday`,
  4: `Thursday`,
  5: `Friday`,
  6: `Saturday`,
  7: `Sunday`,
};
export default function useMenuItems({
  halfView,
  halfItem,
  hhActiveMenuSizes,
  itemVariants,
  selectedHalfItems,
}) {
  const {
    categories,
    menuItems,
    menuItemSizes,
    menuItemSubModifiers,
    currentOrder,

    allSpecials,
    orderSetup,
    soldOutMenuItems,
    currentUser,
    userDetails,
    specialFilter: {
      isSpecialFilterApplied,
      spMenuItems,
      spMenuSizes,
      spSubModifiers,
      spMenuCategories,
    },
    voucherFilter: {
      isVoucherFilterApplied,
      voucherMenuItems,
      voucherMenuSizes,
      voucherSubModifiers,
      voucherMenuCategories,
    },
    ui: { mobileCart },
  } = useSelector((state) => state.homeReducer);
  const { halfHalfSetup = {}, offers } = useSelector(
    (state) => state.layoutReducer
  );
  const storeConfig = useSelector(selectStoreConfig);

  const [fetchedCategories, setFetchedCategories] = useState(categories);
  const [categoriesList, setCategoriesList] = useState(categories);
  const [filteredCategoriesList, setFilteredCategoriesList] =
    useState(categories);
  const statePopulars = useGetPopulars();
  const orderAgainItems = useOrderAgain({
    orderSetup,
    userDetails,
    currentOrder,
    menuItems,
    menuItemSizes,
    menuItemSubModifiers,
    categoriesList,
  });

  const isOrderAgainAvailable = orderAgainItems?.length > 0;

  useEffect(() => {
    const cachedCategories = categories;

    if (!cachedCategories || !menuItems || !currentOrder.orderType) return;

    let newCategories = cachedCategories?.map((m) => {
      m.menuItems = menuItems?.filter(
        (a) =>
          a.categoryId === m._id &&
          a.orderFromKeys?.includes('1') &&
          a.orderTypeKeys?.includes(currentOrder.orderType) &&
          a.isPublished
      );

      // fetch in-store prices for menu items
      m.menuItems = m.menuItems.map((menuItem) => {
        if (
          menuItem.orderFromKeys?.includes('2') &&
          menuItem.orderTypeKeys?.includes(currentOrder.orderType) &&
          menuItem.isPublished &&
          (menuItemSizes?.length || menuItemSubModifiers?.length)
        ) {
          const newMenuItemSizes = menuItemSizes.filter(
            (f) => f.menuItemId === menuItem._id
          );
          const newMenuItemSubModifiers = menuItemSubModifiers.filter(
            (f) => f.menuItemId === menuItem._id
          );
          return getMinifiedMenuItemForInstore(
            menuItem,
            newMenuItemSizes,
            newMenuItemSubModifiers,
            currentOrder.orderType
          );
        } else {
          return menuItem;
        }
      });

      return m;
    });

    let filteredCategories = [];
    if (isSpecialFilterApplied && !halfView) {
      filteredCategories = newCategories?.filter((obj) =>
        spMenuCategories
          ?.filter((f) => f.isActive)
          ?.map((m) => m._id)
          ?.includes(obj._id)
      );
      filteredCategories.forEach((item) => {
        item.filteredMenuItems = item.menuItems.filter((obj) => {
          let i = 0;
          if (
            spMenuItems &&
            spMenuItems.length > 0 &&
            ((spMenuSizes && spMenuSizes.length > 0) ||
              (spSubModifiers && spSubModifiers.length > 0))
          ) {
            let matchedItems = spMenuItems.filter(
              (item) => obj._id === item._id
            );
            if (matchedItems.length > 0) {
              if (matchedItems[0].isActive) {
                obj.selectedSizes.forEach((m) => {
                  spMenuSizes?.forEach((c) => {
                    if (c._id === m._id && c.isActive) i++;
                  });
                  spSubModifiers?.forEach((c) => {
                    if (c._id === m._id && c.isActive) i++;
                  });
                });
                if (matchedItems[0].price) obj.upsale = matchedItems[0].price;
              }
            } else {
              obj.selectedSizes.forEach((m) => {
                spMenuSizes?.forEach((c) => {
                  if (c._id === m._id && c.isActive) i++;
                });
                spSubModifiers?.forEach((c) => {
                  if (c._id === m._id && c.isActive) i++;
                });
              });
              obj.upsale = 0;
            }
          }
          obj.showName = !obj.isNameHidden;
          return i !== 0;
        });
        return item;
      });
    }

    if (isVoucherFilterApplied && !halfView) {
      filteredCategories = newCategories?.filter((obj) =>
        voucherMenuCategories
          ?.filter((f) => f.isActive)
          ?.map((m) => m._id)
          ?.includes(obj._id)
      );
      filteredCategories.forEach((item) => {
        item.filteredMenuItems = item.menuItems.filter((obj) => {
          let i = 0;
          if (
            voucherMenuItems &&
            voucherMenuItems.length > 0 &&
            ((voucherMenuSizes && voucherMenuSizes.length > 0) ||
              (voucherSubModifiers && voucherSubModifiers.length > 0))
          ) {
            let matchedItems = voucherMenuItems.filter(
              (item) => obj._id === item._id
            );
            if (matchedItems.length > 0) {
              if (matchedItems[0].isActive) {
                obj.selectedSizes.forEach((m) => {
                  voucherMenuSizes?.forEach((c) => {
                    if (c._id === m._id && c.isActive) i++;
                  });
                  voucherSubModifiers?.forEach((c) => {
                    if (c._id === m._id && c.isActive) i++;
                  });
                });
                if (matchedItems[0].price) obj.upsale = matchedItems[0].price;
              }
            } else {
              obj.selectedSizes.forEach((m) => {
                voucherMenuSizes?.forEach((c) => {
                  if (c._id === m._id && c.isActive) i++;
                });
                voucherSubModifiers?.forEach((c) => {
                  if (c._id === m._id && c.isActive) i++;
                });
              });
              obj.upsale = 0;
            }
          }
          obj.showName = !obj.isNameHidden;
          return i !== 0;
        });
        return item;
      });
    }

    // Filter for half half view
    if (halfView && halfItem?.categories && hhActiveMenuSizes) {
      const addedKeys = selectedHalfItems.map((m) => m._id);
      filteredCategories = newCategories?.filter((obj) =>
        halfItem.categories.map((m) => m._id).includes(obj._id)
      );
      filteredCategories.forEach((item) => {
        item.filteredMenuItems = item.menuItems.filter((obj) => {
          let i = 0,
            mnt = 0;
          obj.selectedSizes.forEach((m) => {
            if (itemVariants && itemVariants.length > 0) {
              if (hhActiveMenuSizes.map((mh) => mh._id).includes(m._id)) {
                if (m.variants) {
                  let cnt = 0;
                  m.variants.forEach((v) => {
                    let matchingVs = itemVariants.filter(
                      (f) => f._id === v._id && v.isSelected === true
                    );
                    if (matchingVs.length > 0) {
                      cnt = cnt + 1;
                    }
                  });
                  if (cnt === itemVariants.length) i++;
                }
              }
            } else if (hhActiveMenuSizes.map((mh) => mh._id).includes(m._id)) {
              i++;
            }
          });
          if (halfItem?.menuItems) {
            let matchingMs = halfItem.menuItems.filter(
              (m) => m._id === obj._id
            );
            if (matchingMs.length > 0) {
              if (matchingMs[0].isActive) mnt = mnt + 1;
            } else mnt = mnt + 1;
          }
          obj.showName = !obj.isNameHidden;
          return (
            i !== 0 &&
            !includes(addedKeys, obj._id) &&
            ((halfItem.menuItems && mnt !== 0) ||
              (!halfItem.menuItems && mnt === 0))
          );
        });
        return item;
      });
    }

    setFilteredCategoriesList(filteredCategories);
    setFetchedCategories(newCategories);
  }, [
    categories,
    currentOrder.orderType,
    halfItem.categories,
    halfItem.menuItems,
    halfView,
    hhActiveMenuSizes,
    isSpecialFilterApplied,
    itemVariants,
    menuItemSizes,
    menuItemSubModifiers,
    menuItems,
    selectedHalfItems,

    spMenuCategories,
    spMenuItems,
    spMenuSizes,
    spSubModifiers,
    isVoucherFilterApplied,
    voucherMenuCategories,
    voucherMenuItems,
    voucherSubModifiers,
    voucherMenuSizes,
    // setFilteredCategoriesList // ⛔️ Do not add it in dependency list
  ]);

  useEffect(() => {
    /* =====================================><====================================== */
    if (!fetchedCategories.length) return;
    const specials = (allSpecials ?? []).filter(
      (f) =>
        f.orderFromKeys?.includes('1') &&
        f[weekdays[moment().tz(storeConfig.timeZone).format(`E`)]] &&
        f.orderTypeKeys?.includes(currentOrder.orderType) &&
        f.isPublished
    );

    const activeCategories = fetchedCategories?.filter(
      (c) => c.menuItems?.length
    );

    // setting the categories and it's items
    const newCategoriesList = [
      ...(orderAgainItems?.length
        ? [
            {
              name: 'Order Again',
              _id: 'orderAgain',
              displayType: CATEGORY_DISPLAY_TYPE.GRID,
              data: getSimpleAndSpecialMenuItems({
                menuItems: orderAgainItems,
                halfHalfs: halfHalfSetup.halfHalfs,
                halfHalfPlaceholderImage:
                  halfHalfSetup.halfHalfPlaceholderImage,
                halfHalfPlaceholderPath: halfHalfSetup.halfHalfPlaceholderPath,
                isSpecialFilterApplied,
                soldOutMenuItems,
                spMenuSizes,
                itemType: MENU_ITEM_DATA_TYPE.menuitem,
                orderType: currentOrder?.orderType,
              }),
            },
          ]
        : []),
      ...(statePopulars.length
        ? [
            {
              name: 'popular',
              _id: 'popular',
              displayType: CATEGORY_DISPLAY_TYPE.GRID,

              data: statePopulars,
            },
          ]
        : []),
      ...(specials.length
        ? [
            {
              name: orderSetup?.customSpecialTitle || 'specials',
              _id: 'specials',
              isSpecial: true,
              displayType: CATEGORY_DISPLAY_TYPE.GRID,

              data: getSimpleAndSpecialMenuItems({
                menuItems: specials,
                halfHalfs: halfHalfSetup.halfHalfs,
                halfHalfPlaceholderImage:
                  halfHalfSetup.halfHalfPlaceholderImage,
                halfHalfPlaceholderPath: halfHalfSetup.halfHalfPlaceholderPath,
                isSpecialFilterApplied,
                soldOutMenuItems,
                spMenuSizes,
                itemType: MENU_ITEM_DATA_TYPE.special,
                isSpecial: true,
                orderType: currentOrder?.orderType,
              }),
            },
          ]
        : []),
      ...(activeCategories ?? []).map((category) => ({
        name: category.name,
        _id: category._id,
        displayType: category.displayType ?? CATEGORY_DISPLAY_TYPE.GRID,

        data: getSimpleAndSpecialMenuItems({
          menuItems: category.menuItems,
          halfHalfs: halfHalfSetup.halfHalfs,
          halfHalfPlaceholderImage: halfHalfSetup.halfHalfPlaceholderImage,
          halfHalfPlaceholderPath: halfHalfSetup.halfHalfPlaceholderPath,
          isSpecialFilterApplied,
          soldOutMenuItems,
          spMenuSizes,
          itemType: MENU_ITEM_DATA_TYPE.menuitem,
          sortingType: category.sortingType,
          _id: category._id,
          orderType: currentOrder?.orderType,
        }),
      })),
    ];

    setCategoriesList(newCategoriesList);
  }, [
    fetchedCategories,
    allSpecials,
    currentOrder?.orderType,
    halfHalfSetup.halfHalfPlaceholderImage,
    halfHalfSetup.halfHalfPlaceholderPath,
    halfHalfSetup.halfHalfs,
    isSpecialFilterApplied,
    orderSetup?.customSpecialTitle,
    statePopulars,
    soldOutMenuItems,
    spMenuSizes,
    storeConfig.timeZone,
    orderAgainItems,
  ]);

  return {
    isOrderAgainAvailable,
    categoriesList,
    filteredCategoriesList,
    isSpecialFilterApplied,
    halfHalfSetup,
    spSubModifiers,
    isVoucherFilterApplied,
    menuItems,
    storeConfig,
    orderSetup,
    currentOrder,
    mobileCart,
    offers,
    currentUser,
    userDetails,
  };
}
