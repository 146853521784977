import getImage from 'helpers/getImage';
import ItemView from '../../components/ItemView';

export default function HalfOrderView({
  ingredientCategories,
  orderItem,
  closeOrder,
  itemInProgress,
  addToOrder,
  rightSideViewVisibilityIndex,

  sidebarAnimationClass,
}) {
  const renderSec = () => {
    const image = getImage(orderItem.mediaPath || orderItem.urlS3);
    return (
      <ItemView
        ingredientCategories={ingredientCategories}
        orderItem={orderItem}
        name={orderItem && orderItem.name ? orderItem.name : ''}
        imageUrl={orderItem && image ? image : ''}
        description={
          orderItem && orderItem.description ? orderItem.description : ''
        }
        closeItem={closeOrder}
        itemInProgress={itemInProgress}
        addToOrder={addToOrder}
        rightSideViewVisibilityIndex={rightSideViewVisibilityIndex}
        openedFrom={'half'}
      />
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      {itemInProgress && renderSec()}
    </div>
  );
}
