import React, { useEffect, useRef, useState } from 'react';
import plustwo from '../../assets/images/plus2.svg';
import orderBy from 'lodash/orderBy';
import { batch, useDispatch } from 'react-redux';
import {
  filterView,
  toggleMobileCart,
  voucherFilterView,
} from 'containers/Home/actions';
import store from '../../store';
import { isEqual } from 'lodash';
import SpecialBoxesSkeleton from 'components/SpecialBoxesSkeleton';
import getImage from 'helpers/getImage';

function getMenuItems() {
  const {
    menuItems,
    voucherFilter: { voucherMenuSizes, voucherSubModifiers },
  } = store.getState().homeReducer;

  return {
    menuItems,
    voucherMenuSizes,
    voucherSubModifiers,
  };
}

export default function VoucherSpecialBox({
  boxIndex,
  menuItems,
  showSpecialItem,
  addSpecialToOrder,
  halfHalfSetup,
  selectedSpecials,
  specialItem,
  currentOrder,

  itemExist,
  storeConfig,
  activeIndex,
  specialBoxes,
  setOrderState,
}) {
  const dispatch = useDispatch();
  const specialBoxTitleRef = useRef(null);
  const [specialBoxTitleHeight, setSpecialBoxTitleHeight] = useState(0);

  useEffect(() => {
    setSpecialBoxTitleHeight(specialBoxTitleRef.current?.clientHeight);
  }, [specialBoxTitleRef]);

  const applyFilterView = (
    voucherMenuItems,
    voucherMenuSizes,
    voucherSubModifiers,
    voucherMenuCategories,
    boxIndex
  ) => {
    let itemExist = getImageUrl(boxIndex) ? boxIndex : -1;
    setOrderState({
      activeIndex: boxIndex,
    });
    batch(() => {
      dispatch(dispatch(toggleMobileCart(false)));
      dispatch(
        voucherFilterView({
          isVoucherFilterApplied: true,
          voucherBoxIndex: boxIndex,
          itemExist,
          voucherMenuItems,
          voucherMenuSizes,
          voucherSubModifiers,
          voucherMenuCategories,
        })
      );
    });
  };

  const callOnce = useRef(false);
  const specialItemRef = useRef(null);
  useEffect(() => {
    if (callOnce.current && isEqual(specialItemRef.current, specialItem))
      return;
    if (
      (specialItem && specialItem.selectedCategories && !specialBoxes) ||
      selectedSpecials
    ) {
      callOnce.current = true;
      specialItemRef.current = specialItem;
      const specialBoxes = [];
      let z = 0;

      specialItem?.selectedCategories
        ?.sort((a, b) => a.index - b.index)
        ?.forEach((sp) => {
          let i = 0;
          for (; i < Number(sp.quantity); i++) {
            let obj = JSON.parse(JSON.stringify(sp));
            obj.boxIndex = i + z;
            specialBoxes.push(obj);
          }
          z += i;
        });

      const filteredSelectedSpecials = selectedSpecials
        ? selectedSpecials.filter((m) => m.voucherId === specialItem._id)
        : [];

      if (filteredSelectedSpecials.length > 0) {
        let nextItem = null;
        const { menuItems } = getMenuItems();
        const menuItemsMap = menuItems.reduce(
          (acc, i) => ({ ...acc, [i._id]: i }),
          {}
        );
        let lastIndex = boxIndex + 1;
        for (let i = lastIndex; i < specialBoxes.length; i++) {
          const currentSpecialBox = specialBoxes[i];
          let currentItemExist = getImageUrl(currentSpecialBox.boxIndex)
            ? currentSpecialBox.boxIndex
            : -1;
          const currentActiveMenuItems = currentSpecialBox.menuItems.filter(
            (i) => i.isActive
          );
          const item = menuItemsMap[currentActiveMenuItems[0]._id];
          if (
            currentItemExist !== -1 &&
            currentActiveMenuItems.length === 1 &&
            item.selectedExtraIngredients.length === 0 &&
            item.selectedIngredients.length === 0 &&
            item.selectedModifiers?.length === 0
          ) {
            lastIndex++;
          } else {
            nextItem = currentSpecialBox;
            break;
          }
        }

        if (
          nextItem &&
          window.innerWidth > 991 &&
          !(
            window.navigator.userAgent.match(/iPad/i) &&
            window.innerHeight > 1250
          )
        ) {
          setOrderState({ specialBoxes });
          applyFilterView(
            nextItem.menuItems ?? [],
            nextItem.menuSizes ?? [],
            nextItem.subModifiers ?? [],
            nextItem.categories ?? [],
            lastIndex
          );

          const activeMenuItems = nextItem.menuItems.filter((i) => i.isActive);
          if (activeMenuItems.length === 1) {
            setTimeout(() => {
              // we get menuItems from store because we need to get the latest version of menuItems
              // and setTimout is used to wait for the menuItems to be updated in store
              const { menuItems, voucherMenuSizes, voucherSubModifiers } =
                getMenuItems();
              const menuItemsMap = menuItems.reduce(
                (acc, i) => ({ ...acc, [i._id]: i }),
                {}
              );
              const item = menuItemsMap[activeMenuItems[0]._id];
              showSpecialItem(item, voucherMenuSizes, voucherSubModifiers);
            }, 0);
          }
        } else {
          setOrderState({ specialBoxes, activeIndex: lastIndex + 1 });
        }
      } else {
        setOrderState({ specialBoxes });

        const { menuItems, menuSizes, subModifiers, categories } =
          specialBoxes?.find(({ index }) => index === 0) || {};

        if (window.innerWidth > 991) {
          applyFilterView(menuItems, menuSizes, subModifiers, categories, 0);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    // applyFilterView,
    boxIndex,
    menuItems,
    selectedSpecials,
    showSpecialItem,
    specialItem,
  ]);

  const getImageUrl = (boxIndex) => {
    if (specialItem && selectedSpecials) {
      let sps = selectedSpecials.filter((m) => {
        return m.voucherId === specialItem._id && m.boxIndex === boxIndex;
      });
      if (sps && sps.length === 1) {
        return getImage(sps[0].mediaPath || sps[0].urlS3);
      }
      if (sps && sps.length > 1) {
        return getImage(
          halfHalfSetup?.halfHalfPlaceholderPath ||
            halfHalfSetup?.halfHalfPlaceholderImage
        );
      }
    }
  };

  const getName = (boxIndex) => {
    if (specialItem && selectedSpecials) {
      let sps = selectedSpecials.filter((m) => {
        return m.voucherId === specialItem._id && m.boxIndex === boxIndex;
      });
      if (sps && sps.length === 1) {
        return sps[0].name;
      }
      if (sps && sps.length > 1) {
        return sps.map(({ name }) => name).join(' / ');
      }
      return false;
    }
    return false;
  };

  const getNameEmpty = (specialBox) => {
    if (
      specialBox.categories &&
      (specialBox.menuSizes || specialBox.subModifiers)
    ) {
      let activeCategories = specialBox.categories.filter((f) => f.isActive);
      if (specialBox.displayName) {
        return specialBox.displayName;
      } else {
        let arrayDesciptions = [];
        activeCategories.forEach((c) => {
          let activeSizes = specialBox.menuSize
            ? specialBox.menuSizes.filter(
                (f) => f.isActive && f.categoryId === c._id
              )
            : [];
          let activeSubModifiers = specialBox.subModifiers
            ? specialBox.subModifiers.filter((f) => f.isActive)
            : [];
          arrayDesciptions.push(
            `${[...activeSizes, ...activeSubModifiers]
              .map((m) => m.name)
              .join('/')} ${c.name}`
          );
        });
        return arrayDesciptions.join(' or ');
      }
    }
    return '';
  };

  const isSubmitEnabled = () => {
    if (specialItem && specialItem.selectedCategories && selectedSpecials) {
      let count = 0;
      let selectedCount = 0;
      specialItem.selectedCategories.forEach((m) => {
        count += Number(m.quantity);
      });
      selectedSpecials.forEach((s) => {
        selectedCount += Number(s.quantity);
      });
      if (selectedCount >= count) return true;
      return false;
    }
    return false;
  };

  const renderAddSpecialToOrder = () => {
    if (isSubmitEnabled()) {
      addSpecialToOrder(specialItem);
    }
  };

  const cost = specialBoxes?.length * specialItem?.discountedPrice;

  return (
    <div className='specialViewI'>
      {specialItem && (
        <div
          className='detailsTitle sP'
          style={{ marginTop: 0 }}
          ref={specialBoxTitleRef}
        >
          {!specialItem.isNameCanBeUsedInFrame
            ? specialItem.description
              ? specialItem.description.length >= 100
                ? specialItem.description.substring(0, 50).concat('...')
                : specialItem.description
              : ''
            : specialItem.displayName
            ? specialItem.displayName
            : specialItem.name}
        </div>
      )}
      <div
        className='boxesSP'
        style={{ height: `calc(100% - ${specialBoxTitleHeight}px - 54px)` }}
      >
        {specialBoxes &&
          orderBy(specialBoxes, ['index'], ['asc']).map((sp, index) => (
            <div
              key={`${index} index`}
              className={
                activeIndex === index || itemExist === index
                  ? 'active bSp'
                  : 'bSp'
              }
              onClick={() => {
                applyFilterView(
                  sp.menuItems || [],
                  sp.menuSizes || [],
                  sp.subModifiers || [],
                  sp.categories || [],
                  index
                );
              }}
            >
              <div className='innerSP'>
                {getImageUrl(index) && itemExist !== index ? (
                  <img src={getImageUrl(index)} alt='' />
                ) : (
                  itemExist !== index && (
                    <span>
                      <img src={plustwo} alt='' />
                      Add
                    </span>
                  )
                )}
                <div className={getName(index) ? 'activeName' : 'nonActive'}>
                  {getName(index)
                    ? getName(index)
                    : getNameEmpty(sp)
                    ? getNameEmpty(sp).length >= 10
                      ? getNameEmpty(sp).substring(0, 10).concat('...')
                      : getNameEmpty(sp)
                    : ''}
                </div>
              </div>
            </div>
          ))}
        {specialBoxes?.length <= 0 && <SpecialBoxesSkeleton />}
      </div>
      <div className='spBtn'>
        <div
          onClick={renderAddSpecialToOrder}
          className={
            isSubmitEnabled() ? 'confirmBtn' : 'confirmBtn diableConfirm'
          }
        >
          Add to Order
          {specialItem && specialItem.type !== '2' && (
            <span className='rb'>{` ${storeConfig.currency}${cost}`}</span>
          )}
        </div>
      </div>
    </div>
  );
}
