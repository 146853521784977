import SimpleOrSpecialMenuItemsCategoryContainer from 'components/NewMenuItem/SimpleOrSpecialMenuItemsCategoryContainer';
import { SOLD_OUT_ITEM_PRICE } from 'helpers/enums';
import { useMemo } from 'react';
import MenuItemsLoadingSkeleton from './MenuItemsLoadingSkeleton';
import SearchModal from './SearchModal';
import getImage from 'helpers/getImage';

export default function RenderSimpleAndSpecialMenuItems({
  categoriesList,
  onClickItemHandler,
  allRefs,
  currency,
}) {
  const searchCategoriesList = useMemo(() => {
    return (categoriesList ?? [])
      .map((category) => ({
        name: category?.name,
        data: category?.data
          .filter((item) => item.price !== SOLD_OUT_ITEM_PRICE)
          .map((item) => ({
            ...item.item,
            name: item.item.name ?? item.productName,
            itemObjType: item.itemType,
            urlS3: getImage(
              item.item.mediaPath || item.item.urlS3 || item.bgImgSrc
            ),
            unitPrice: item.price,
          })),
      }))
      .filter((category) => category?.data?.length > 0);
  }, [categoriesList]);

  return (
    <>
      {categoriesList?.length > 0 ? (
        <>
          {categoriesList.map((category, index) => (
            <SimpleOrSpecialMenuItemsCategoryContainer
              key={index}
              ref={allRefs[index]}
              category={category}
              onClickItemHandler={onClickItemHandler}
              currency={currency}
            />
          ))}

          {/* Display skeleton view when only specials and populars are loaded */}
          {categoriesList.length <= 0 && <MenuItemsLoadingSkeleton />}
        </>
      ) : (
        <MenuItemsLoadingSkeleton />
      )}

      <SearchModal
        searchCategoriesList={searchCategoriesList}
        onClickItemHandler={onClickItemHandler}
      />
    </>
  );
}
