import ActionTypes from './constants';

const isUpperCase = (string) => /^[A-Z]*$/.test(string);

const country = window.storeConfig?.country || '';

const countryArr = country.split(' ');
const countryAbv =
  countryArr?.length > 1 &&
  countryArr?.reduce((acc, countryPart) => {
    if (isUpperCase(countryPart[0])) {
      return acc + countryPart[0];
    }
    return acc;
  }, '');

export const LAYOUT_REDUCER_INITIAL_STATE = {
  storeConfig: { ...window.storeConfig, countryAbv } || {},
};

console.log(
  '%c 🥟 LAYOUT_REDUCER_INITIAL_STATE: ',
  'font-size:20px;background-color: #3F7CFF;color:#fff;',
  LAYOUT_REDUCER_INITIAL_STATE
);

window.storeConfigInitial = { ...window.storeConfig };
window.storeConfig = null;

export default function layoutReducer(
  state = LAYOUT_REDUCER_INITIAL_STATE,
  action
) {
  switch (action.type) {
    case `@layout/setState`:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.InitializeLayout:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
