import LeafletDriverOnTheWay from '../../components/LeafletDriverOnTheWay';
import './leafletMarkerSlideTo';
import 'leaflet-rotatedmarker';
import Map from 'components/Map';
import { MENU_ORDER_TYPE } from 'helpers/enums';

const DriverTracker = ({ currentOrder, store, driver }) => {
  const markerOptions = {
    height: 50,
    width: 40,
  };
  const genericStatuses = ['0', '2', '4', '11', '25'];
  const driverStatuses = ['3', '13', '12'];
  const { addressLocation: storeLocation } = store || {};
  const {
    orderType,
    orderStatus,
    addressLocation: currentOrderLocation,
  } = currentOrder || {};
  const {
    currentLocation: driverCurrentLocation,
    previousLocation: driverPreviousLocation,
  } = driver || {};

  const isDeliveryLocation = Boolean(
    orderType === MENU_ORDER_TYPE.delivery &&
      genericStatuses.includes(orderStatus) &&
      currentOrderLocation
  );
  const isDriverLocation = Boolean(
    orderType === MENU_ORDER_TYPE.delivery &&
      driverStatuses.includes(orderStatus) &&
      currentOrderLocation &&
      driverCurrentLocation &&
      driverPreviousLocation
  );

  let lat = storeLocation?.lat;
  let long = storeLocation?.long;
  let customMarker = `${window.storeConfigInitial?.storageBaseUrl}/assets/S+P+I+con.svg`;

  if (isDriverLocation || isDeliveryLocation) {
    lat = currentOrderLocation?.lat;
    long = currentOrderLocation?.lng;
    customMarker = `${window.storeConfigInitial?.storageBaseUrl}/assets/marker.svg`;
  }

  return isDriverLocation ? (
    <LeafletDriverOnTheWay delivery={currentOrderLocation} driver={driver} />
  ) : (
    <Map
      lat={lat}
      long={long}
      customMarker={customMarker}
      markerOptions={markerOptions}
      dragPan={false}
      dragRotate={false}
      scrollZoom={false}
    />
  );
};

export default DriverTracker;
