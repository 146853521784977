import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DescriptionUI,
  ModalCWShellUI,
} from '@next-order/next-order-components';
import getImage from 'helpers/getImage';

function NotificationModal({ orderSetup }) {
  const {
    isNotificationOn,
    notificationImage,
    notificationImagePath,
    notificationTitle,
    notificationMessage,
    notificationButtonText,
  } = orderSetup || {};
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const image = notificationImagePath || notificationImage;

  useEffect(() => {
    if (isNotificationOn && notificationMessage) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationOn, notificationMessage]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <ModalCWShellUI
          maxWidth={460}
          open={showModal}
          onOpenChange={setShowModal}
          zIndex={9999}
          maxHeight='full'
          isLoading={!!image && isLoading}
        >
          <ModalCWShellUI.CloseButton
            onClick={() => setShowModal(false)}
            extendedClassName='ml-auto right-4'
          />
          <ModalCWShellUI.Header
            extendedHeadingClassName='w-full text-center'
            title={notificationTitle}
          />
          <ModalCWShellUI.Body
            isTopShadow
            isBottomShadow
            extendedClassName='py-6'
          >
            {!image && notificationMessage && (
              <DescriptionUI
                fontSize='custom'
                lineHeight='custom'
                fontWeight='light'
                extendedClassName='text-4.625 text-primary-text leading-[34px]'
              >
                {notificationMessage}
              </DescriptionUI>
            )}
            {!!image && (
              <div className='relative w-full h-auto'>
                <img
                  src={getImage(image)}
                  alt='notification'
                  className='w-full h-auto object-contain'
                  onError={(e) => {
                    e.target.src = notificationImage;
                  }}
                  onLoad={() => {
                    setIsLoading(false);
                  }}
                />
              </div>
            )}
          </ModalCWShellUI.Body>
          {notificationButtonText && (
            <ModalCWShellUI.Footer>
              <ModalCWShellUI.Button
                title={notificationButtonText}
                onClick={closeModal}
              >
                {notificationButtonText}
              </ModalCWShellUI.Button>
            </ModalCWShellUI.Footer>
          )}
        </ModalCWShellUI>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const {
    homeReducer: { orderSetup },
  } = state;

  return {
    orderSetup,
  };
}

export default connect(mapStateToProps, null)(NotificationModal);
