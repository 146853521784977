import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import PropType from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ButtonGroup from '../../components/ButtonGroup';
import Ingredients from '../../components/Ingredients';
import { selectStoreConfig } from '../../containers/Layout/selectors';

import { ButtonUI, CloseButtonCWUI } from '@next-order/next-order-components';
import { Function } from 'components/Accordion/function';
import { eventNames } from 'configuration/eventNames';
import { SOLD_OUT_ITEM_PRICE, VISIBILITY_INDEX_TYPE } from 'helpers/enums';
import sortBy from 'lodash/sortBy';
import { firebaseAnalyticsNO } from '../../firebase';
import Accordion from '../Accordion';
import AddExtrasSection from './AddExtrasSection';
import './main.css';
import { CheckIcon, PlusIcon } from '@heroicons/react/solid';
import { isEqual } from 'lodash';
import ItemSkeleton from 'components/ItemSkeleton';
import getGroupedModifiers from './getGroupedModifiers';

class ItemView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movePriceSection: false,
      singleSize: false,
      toggleExtrasSection: false,
      toggleTransition: false,
      isTabTransition: false,
      toggleIngredientsSection: false,
      ingredientCategories: [],
      selectedVariants: [],
      itemVariants: [],
      width: 0,
      left: 20,
      active: 0,
      removedIngCount: 0,
      addedExtraCount: 0,
      extraPrice: 0,
      selectedTab: 0,
    };
    this.swiper = null;
  }

  initItem = (item) => {
    //setting green bar width for first item in extras slider
    let width = 0;
    let ingredientCategories = [];
    let popularIngredients = [];
    let singleSize = false;
    let itemInProgress = JSON.parse(JSON.stringify(item));

    if (this.refs[0] !== undefined) {
      width = this.refs[0].offsetWidth;
    }

    //making specialised list of extras with item belongs to category of it, also contains logic of hide extras button if it's not avilable in list
    if (this.props.ingredientCategories) {
      ingredientCategories = JSON.parse(
        JSON.stringify(this.props.ingredientCategories)
      );
    }

    if (ingredientCategories.length > 0) {
      ingredientCategories.map((item) => {
        if (this.props.itemInProgress.selectedIngredients) {
          item.ingredientList =
            this.props.itemInProgress.selectedIngredients.filter((obj) => {
              return obj.ingredientCategory === item._id;
            });
        }
        if (this.props.itemInProgress.selectedExtraIngredients) {
          item.extraList =
            this.props.itemInProgress.selectedExtraIngredients.filter((obj) => {
              return obj.ingredientCategory === item._id;
            });
          item.extraList = orderBy(item.extraList, ['agregateSales'], ['desc']);
        }
        return item;
      });

      ingredientCategories.map((category) => {
        category?.extraList?.map((item) => {
          let finalPrices = [];
          if (item.prices) {
            item.prices.map((pp) => {
              if (pp.isSelected) {
                let pObj = {
                  _id: pp._id,
                  name: pp.name,
                  price: pp.price,
                  isVariant: false,
                  _vId: 'xxx',
                  _vName: 'xxx',
                };
                finalPrices.push(pObj);
                if (pp.variants) {
                  pp.variants.map((vv) => {
                    if (vv.isSelected) {
                      let vObj = {
                        _id: pp._id,
                        name: pp.name,
                        isVariant: true,
                        _vId: vv._id,
                        _vName: vv.name,
                        price: Number(
                          Number(pp.price) + Number(vv.price)
                        ).toFixed(2),
                      };
                      finalPrices.push(vObj);
                    }
                    return vv;
                  });
                }
              }
              return pp;
            });
          }
          item.finalPrices = finalPrices;
          return item;
        });
        return category;
      });

      ingredientCategories = ingredientCategories.filter((item) => {
        return item.extraList && item.extraList.length > 0;
      });

      let allExtrasList = [];
      ingredientCategories.forEach((category) => {
        category.extraList.forEach((item) => {
          allExtrasList.push(item);
        });
      });

      allExtrasList = sortBy(allExtrasList, ['agregateSales']).reverse();

      var categoryObj = {};

      allExtrasList.forEach(
        (extraCategory) => (categoryObj[extraCategory.ingredientCategory] = 0)
      );

      var finalArray = [];

      allExtrasList.forEach((menuItem) => {
        if (categoryObj[menuItem.ingredientCategory] < 8) {
          finalArray.push(menuItem);
          categoryObj[menuItem.ingredientCategory] =
            categoryObj[menuItem.ingredientCategory] + 1;
        }
      });

      for (let i = 2; i < finalArray.length; i++) {
        const current = finalArray[i];
        if (
          current.ingredientCategory === finalArray[i - 1].ingredientCategory &&
          current.ingredientCategory === finalArray[i - 2].ingredientCategory
        ) {
          // find next valid element
          let j = i + 1;
          while (
            j < finalArray.length - 1 &&
            current.ingredientCategory === finalArray[j].ingredientCategory
          ) {
            j++;
          }
          if (j < finalArray.length - 1) {
            let replace = finalArray.splice(j, 1);
            finalArray.splice(i, 0, replace[0]);
          }
        }
      }
      popularIngredients = finalArray.slice(0, 8).filter(Boolean);
    }

    // if item contains only one size than setting default quantity to one
    if (item.selectedSizes?.length === 1 && !item.isSoldOut) {
      singleSize = true;
      this.setState({ movePriceSection: true }, () => {
        setTimeout(() => {
          this.setState({ movePriceSection: false });
        }, 500);
      });
    } else {
      singleSize = false;
    }

    // item variants
    let itemVariants = [];
    if (item && item.selectedSizes && (item.itemType || item.itemType === 0)) {
      item.selectedSizes.map((s) => {
        const isVariants =
          this.props.rightSideViewVisibilityIndex !==
          VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW
            ? s.variants
            : s.variants && s.quantity > 0;

        if (isVariants) {
          s.variants.map((v) => {
            if (v.isSelected) {
              let matchingVariants = itemVariants.filter((ss) => {
                return ss._id === v._id;
              });
              if (matchingVariants.length <= 0) itemVariants.push(v);
              else if (v.quantity > 0) {
                itemVariants = itemVariants.filter((ss) => {
                  return ss._id !== v._id;
                });
                itemVariants.push(v);
              }
            }
            return v;
          });
        }
        if (s.quantity > 0) this.sSize = s;
        return s;
      });
      itemVariants.map((v) => {
        if (v.quantity > 0) {
          v.isClicked = true;
        } else {
          v.isClicked = false;
        }
        return v;
      });
    }

    // item modifiers
    let modifiers = [];
    let maxModifierDisplayOrder = 0;
    if (
      item &&
      item.selectedModifiers &&
      item.itemType &&
      item.itemType !== 0
    ) {
      let subModifiersForBaseModifier = [];

      itemInProgress.selectedModifiers.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.name,
          sName: '',
          displayOrder: m.displayOrder || i,
          subModifiers: [],
        };
        if (m._id === itemInProgress.baseModifierId && m.subModifiers) {
          m.subModifiers.map((s) => {
            let matchedSizes = itemInProgress?.selectedSizes?.filter((f) => {
              return f._id === s._id && f.isDisplayForSpecialOrHalfHalf;
            });
            subModifiersForBaseModifier.push(...matchedSizes);

            let sObj = {
              _id: s._id,
              name: s.name,
              isVariant: false,
              _vId: 'xxx',
              _vName: 'xxx',
              price: s.price,
              editQuantity: s.quantity,
              quantity: matchedSizes.length > 0 ? 1 : 0,
              isDisplayForSpecialOrHalfHalf:
                matchedSizes.length > 0 ? true : false,
              displayOrder: s.displayOrder || 0,
              isOptionAvailable: true,
            };

            if (s.variants) {
              s.variants.forEach((v, vi) => {
                if (v.isSelected) {
                  if (v.quantity) {
                    sObj.editQuantity = 0;
                  }
                  let vObj = {
                    _id: s._id,
                    name: s.name,
                    isVariant: true,
                    _vId: v._id,
                    _vName: v.name,
                    price: Number(Number(s.price) + Number(v.price)).toFixed(2),
                    editQuantity: v.quantity,
                    quantity: matchedSizes.length > 0 ? 1 : 0,
                    isDisplayForSpecialOrHalfHalf:
                      matchedSizes.length > 0 ? true : false,
                    displayOrder:
                      Number(s.displayOrder) + Number((vi + 1) * 0.1),
                    isOptionAvailable: true,
                  };
                  obj.subModifiers.push(vObj);
                }
              });
            }
            obj.subModifiers.push(sObj);
            return s;
          });
        } else {
          m.subModifiers &&
            m.subModifiers.forEach((s, i) => {
              let editQuantity = 0;
              let sObj = {
                _id: s._id,
                name: s.name,
                isVariant: false,
                isDisplayForSpecialOrHalfHalf: true,
                _vId: 'xxx',
                _vName: 'xxx',
                prices: [],
                displayOrder: s.displayOrder || i,
                isOptionAvailable: true,
              };
              if (s.prices) {
                if (subModifiersForBaseModifier?.length === 1) {
                  const matchingPrice = s.prices.filter((p) => {
                    return (
                      p._id === subModifiersForBaseModifier[0]?._id &&
                      p._vId === subModifiersForBaseModifier[0]?._vId
                    );
                  });

                  if (matchingPrice.length > 0 && matchingPrice[0].isSelected) {
                    sObj.isOptionAvailable = true;
                  } else {
                    sObj.isOptionAvailable = false;
                  }
                }
                s.prices.forEach((pp) => {
                  if (pp.quantity) {
                    editQuantity = pp.quantity;
                  }
                  let pObj = {
                    _id: pp._id,
                    name: pp.name,
                    isVariant: false,
                    price: pp.price,
                    _vId: 'xxx',
                    _vName: 'xxx',
                    isSelected: pp.isSelected,
                  };
                  sObj.prices.push(pObj);

                  pp.variants?.forEach((vv) => {
                    if (vv.isSelected) {
                      let vObj = {
                        _id: pp._id,
                        name: pp.name,
                        isVariant: true,
                        _vId: vv._id,
                        _vName: vv.name,
                        price: Number(
                          Number(pp.price) + Number(vv.price)
                        ).toFixed(2),
                        isSelected: pp.isSelected,
                      };
                      sObj.prices.push(vObj);
                    }
                  });
                });
              }
              sObj.editQuantity = editQuantity;
              obj.subModifiers.push(sObj);
            });
        }

        if (maxModifierDisplayOrder < obj?.displayOrder) {
          maxModifierDisplayOrder = obj.displayOrder;
        }
        modifiers.push(obj);
        return m;
      });

      modifiers.push({
        subModifiers: [],
        name: 'Customise',
        isCustomiseSection: true,
        displayOrder: maxModifierDisplayOrder + 1,
        _id: 'xxx-xxx-xxx',
      });

      if (
        this.props.rightSideViewVisibilityIndex ===
        VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
      ) {
        if (item && item.itemVariants && item.itemVariants.length > 0) {
          // debugger;
          modifiers.map((m) => {
            if (
              m._id === itemInProgress.baseModifierId &&
              m.subModifiers &&
              m.subModifiers.length > 0
            ) {
              m.subModifiers = m.subModifiers.filter((s) => {
                return item?.itemVariants?.map((m) => m._id).includes(s._vId);
              });
            }
            return m;
          });
        } else {
          modifiers.map((m) => {
            if (
              m._id === itemInProgress.baseModifierId &&
              m.subModifiers &&
              m.subModifiers.length > 0
            ) {
              m.subModifiers = m.subModifiers.filter((s) => {
                return s._vId === 'xxx';
              });
            }
            return m;
          });
        }
      }

      modifiers = orderBy(modifiers, 'displayOrder');

      if (!item.isSoldOut) {
        modifiers.map((m) => {
          if (m._id !== 'xxx-xxx-xxx' && m.subModifiers.length === 1) {
            m.subModifiers.map((s) => {
              s.quantity =
                this.props.rightSideViewVisibilityIndex ===
                VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
                  ? 0.5
                  : 1;
              m.sName = `${s.name}`;
              return s;
            });
          } else if (
            m._id !== 'xxx-xxx-xxx' &&
            (this.props.rightSideViewVisibilityIndex ===
              VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW ||
              this.props.rightSideViewVisibilityIndex ===
                VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW ||
              this.props.rightSideViewVisibilityIndex ===
                VISIBILITY_INDEX_TYPE.VOUCHER_ORDER_VIEW) &&
            m.subModifiers.filter(
              (sm) => sm.isDisplayForSpecialOrHalfHalf && sm.isOptionAvailable
            ).length === 1
          ) {
            m.subModifiers
              .filter(
                (sm) => sm.isDisplayForSpecialOrHalfHalf && sm.isOptionAvailable
              )
              .map((s) => {
                s.quantity =
                  this.props.rightSideViewVisibilityIndex ===
                  VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
                    ? 0.5
                    : 1;
                m.sName = `${s.name}`;
                return s;
              });
          } else {
            m.subModifiers &&
              m.subModifiers.map((s) => {
                if (s.editQuantity) {
                  s.quantity = s.editQuantity || 0;
                  s.sName = `${s.name}`;
                } else {
                  s.quantity = 0;
                }
                delete s.editQuantity;
                return s;
              });
          }
          return m;
        });
      }
      let lastIndexNeedsprocessing = -1;

      modifiers.forEach((modifier, index) => {
        let matchingSubModifiers = modifier.subModifiers.filter((s) => {
          return s.quantity > 0;
        });
        if (
          matchingSubModifiers.length <= 0 &&
          lastIndexNeedsprocessing === -1
        ) {
          lastIndexNeedsprocessing = index;
        }
      });

      modifiers.forEach((m, index) => {
        if (index === lastIndexNeedsprocessing) {
          m.isOpen = true;
          m.isDisabled = false;
        } else {
          m.isOpen = false;
          m.isDisabled = index > lastIndexNeedsprocessing ? true : false;
        }
      });

      itemInProgress.modifiers = modifiers;

      let matchingBaseModifiers = itemInProgress.modifiers.filter((p) => {
        return p._id === itemInProgress.baseModifierId;
      });
      let matchedModifier = matchingBaseModifiers
        ? matchingBaseModifiers[0]
        : undefined;
      let matchedSubModifiers = matchedModifier?.subModifiers.filter((f) => {
        return f.quantity > 0;
      });

      itemInProgress.modifiers.map((m) => {
        if (m._id === 'xxx-xxx-xxx' && matchedSubModifiers) {
          m.subModifiers = matchedSubModifiers;
          this.sBaseSubModifier = matchedSubModifiers[0];
        }
        return m;
      });
    }
    let groupsMultiModifiers = null;
    if (itemInProgress?.isMultiselectAvailable) {
      const groups = itemInProgress?.modifiers
        ? getGroupedModifiers(itemInProgress.modifiers)
        : {};
      const groupedModifiers = groups?.groupedModifiers;
      groupsMultiModifiers = groups?.groupsMultiModifiers;
      if (groupedModifiers && groupsMultiModifiers) {
        itemInProgress.isMultiselectAvailable = true;
        itemInProgress.modifiers = [...groupedModifiers];
      } else {
        itemInProgress.isMultiselectAvailable = false;
      }
    }

    return {
      groupsMultiModifiers,
      itemInProgress,
      modifiers,
      itemVariants,
      width,
      ingredientCategories,
      popularIngredients,
      singleSize,
    };
  };

  UNSAFE_componentWillMount() {
    if (
      (this.props.itemInProgress && !this.state.itemInProgress) ||
      (this.props.itemInProgress &&
        this.state.itemInProgress &&
        this.props.itemInProgress._id !== this.state.itemInProgress._id)
    ) {
      const itemConfig = this.initItem(this.props.itemInProgress);
      this.setState(
        {
          itemInProgress: this.props.itemInProgress,
          orderItem: this.props.orderItem,
          ...itemConfig,
          isItemInProgressLoading:
            !!this.props.itemInProgress?.abbreviationData,
        },
        () => {
          this.addIngredientToItem();
          this.addExtasInItem();
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (!isEqual(this.props.itemInProgress, this.state.itemInProgress)) {
      const itemConfig = this.initItem(this.props.itemInProgress);
      this.setState(
        {
          itemInProgress: this.props.itemInProgress,
          orderItem: this.props.orderItem,
          ...itemConfig,
          isItemInProgressLoading:
            !!this.props.itemInProgress?.abbreviationData,
        },
        () => {
          this.addIngredientToItem();
          this.addExtasInItem();
        }
      );
    }
  }

  toggleVariant = (variant) => {
    let itemVariants = JSON.parse(JSON.stringify(this.state.itemVariants));
    itemVariants.forEach((v) => {
      if (v._id === variant._id) v.isClicked = !v.isClicked;
    });
    this.setState(
      {
        itemVariants,
      },
      () => {
        let itemForOrder = JSON.parse(JSON.stringify(this.state.orderItem));

        // XXX: really wild function, don't understand what's even happening here
        const sameStuff = (item) => {
          let count = 0;

          if (item.variants) {
            item.variants.forEach((v) => {
              let matchingV = itemVariants.find(
                (f) => f._id === v._id && v.isSelected === true
              );

              if (matchingV && matchingV.isClicked === true) {
                count = count + 1;
                v.quantity = item.quantity;
              } else {
                v.quantity = 0;
              }
            });

            if (count !== itemVariants.filter((f) => f.isClicked).length) {
              item.isDisabled = true;
              item.quantity = 0;
            } else item.isDisabled = false;
          } else {
            if (itemVariants.some((f) => f.isClicked)) {
              item.isDisabled = true;
              item.quantity = 0;
            } else {
              item.isDisabled = false;
            }
          }

          return item;
        };

        itemForOrder.selectedSizes.forEach(sameStuff);

        let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));

        currentItem.selectedSizes.forEach(sameStuff);

        this.setState({
          orderItem: itemForOrder,
          itemInProgress: currentItem,
        });
      }
    );
  };

  incrementItemQuantity = (clickedItem) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));

    let itemVariants = JSON.parse(
      JSON.stringify(this.state.itemVariants.filter((f) => f.isClicked))
    );

    currentItem.selectedSizes.forEach((item) => {
      if (item._id === clickedItem._id) {
        if (item.quantity) {
          item.quantity = item.quantity + 1;
        } else {
          item.quantity = 1;
        }

        item.variants?.forEach((v) => {
          let itemVariantsClicked = itemVariants.filter(
            (f) => f._id === v._id && v.isSelected === true
          );
          if (itemVariantsClicked.length > 0) {
            if (v.quantity) {
              v.quantity = v.quantity + 1;
            } else {
              v.quantity = 1;
            }
          }
        });

        this.sSize = item;
      } else {
        item.quantity = 0;
      }
    });

    // increment global item quantity
    currentItem.quantity = currentItem.quantity + 1;

    this.setState({ itemInProgress: currentItem });
  };

  incrementItemQuantityModifiers = (clickedItem) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
    if (currentItem.isSoldOut) return;
    currentItem.modifiers.forEach((item) => {
      if (item._id === 'xxx-xxx-xxx') {
        item.subModifiers.forEach((s) => {
          if (s._id === clickedItem._id && s._vId === clickedItem._vId) {
            if (s.quantity) {
              s.quantity = s.quantity + 1;
            } else {
              s.quantity = 1;
            }
          } else {
            if (
              this.props.rightSideViewVisibilityIndex ===
              VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW
            ) {
              item.subModifiers.forEach((s) => {
                s.quantity = 0;
              });
            }
          }
        });
      }
      if (item._id === currentItem.baseModifierId) {
        item.subModifiers.forEach((s) => {
          if (s._id === clickedItem._id && s._vId === clickedItem._vId) {
            if (s.quantity) {
              s.quantity = s.quantity + 1;
            } else {
              s.quantity = 1;
            }
          } else {
            if (
              this.props.rightSideViewVisibilityIndex ===
              VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW
            ) {
              item.subModifiers.forEach((s) => {
                s.quantity = 0;
              });
            }
          }
        });
      } else if (item._id !== 'xxx-xxx-xxx') {
        item.subModifiers.forEach((s) => {
          if (s.quantity && s.quantity > 0) {
            s.quantity = s.quantity + 1;
          }
        });
      }
    });
    this.setState({ itemInProgress: currentItem });
  };

  decrementItemQuantity = (clickedItem) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
    let itemVariants = JSON.parse(
      JSON.stringify(this.state.itemVariants.filter((f) => f.isClicked))
    );

    currentItem.selectedSizes.forEach((item) => {
      if (item._id === clickedItem._id) {
        if (item.quantity) {
          item.quantity = item.quantity - 1;
        }

        item.variants?.forEach((v) => {
          let itemVariantsClicked = itemVariants.filter(
            (f) => f._id === v._id && v.isSelected === true
          );
          if (itemVariantsClicked.length > 0) {
            if (v.quantity) {
              v.quantity = v.quantity - 1;
            }
          }
        });
      }
    });

    // increment global item quantity
    currentItem.quantity = currentItem.quantity - 1;

    this.setState({ itemInProgress: currentItem });
  };

  decrementItemQuantityModifiers = (clickedItem) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
    currentItem.modifiers.forEach((item) => {
      if (item._id === 'xxx-xxx-xxx') {
        item.subModifiers.forEach((s) => {
          if (s._id === clickedItem._id && s._vId === clickedItem._vId) {
            if (s.quantity && s.quantity > 1) {
              s.quantity = s.quantity - 1;
            }
          }
        });
      }
      if (item._id === currentItem.baseModifierId) {
        item.subModifiers.forEach((s) => {
          if (s._id === clickedItem._id && s._vId === clickedItem._vId) {
            if (s.quantity && s.quantity > 1) {
              s.quantity = s.quantity - 1;
            }
          }
        });
      } else if (item._id !== 'xxx-xxx-xxx') {
        item.subModifiers.forEach((s) => {
          if (s.quantity && s.quantity > 1) {
            s.quantity = s.quantity - 1;
          }
        });
      }
    });
    this.setState({ itemInProgress: currentItem });
  };

  getItemPrice = () => {
    const { halfHalfSetup } = this.props;
    let itemPrice = 0;
    let quantity = 0;
    let halfQuantity = 0;
    let extraPrice = 0;

    if (this.state.itemInProgress) {
      let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
      if (currentItem?.isSoldOut) {
        return { itemPrice, extraPrice };
      } else if (!currentItem.itemType || currentItem.itemType === 0) {
        currentItem?.selectedSizes?.forEach((item) => {
          if (item.quantity > 0) {
            quantity = quantity + item.quantity;
            itemPrice = itemPrice + item.quantity * Number(item.price);
          }

          item.variants?.forEach((v) => {
            if (v.quantity && Number(v.quantity) > 0)
              itemPrice = itemPrice + item.quantity * Number(v.price);
          });
        });
        if (quantity > 0) {
          currentItem?.selectedExtraIngredients?.forEach((item) => {
            if (item.quantity > 0) {
              extraPrice =
                extraPrice +
                item.quantity * Number(this.getExtraPrice(item, false));
            }
          });
          itemPrice =
            extraPrice *
              (halfHalfSetup?.chargeFullHalfExtraPrice
                ? Math.max(quantity, 1)
                : quantity) +
            itemPrice;
        }
        if (currentItem.isHalf) {
          currentItem?.selectedSizesHalf?.forEach((item) => {
            if (item.quantity > 0) {
              halfQuantity = halfQuantity + item.quantity;
              itemPrice = itemPrice + item.quantity * Number(item.price);
            }

            item.variants?.forEach((v) => {
              if (v.quantity && Number(v.quantity) > 0)
                itemPrice = itemPrice + item.quantity * Number(v.price);
            });
          });
          if (halfQuantity > 0) {
            currentItem?.selectedExtraIngredientsHalf?.forEach((item) => {
              if (item.quantity > 0) {
                extraPrice =
                  extraPrice +
                  item.quantity * Number(this.getExtraPrice(item, false));
              }
            });
            itemPrice = extraPrice * quantity + itemPrice;
          }
        }
      } else if (Array.isArray(currentItem.modifiers)) {
        currentItem.modifiers = currentItem.modifiers.filter(
          (m) =>
            m?.subModifiers.length > 0 &&
            m?.subModifiers?.some((n) => n.isOptionAvailable)
        );
        let selectedSubModifier = { _id: 0 };
        currentItem.modifiers.forEach((m) => {
          if (m._id === 'xxx-xxx-xxx' && m.subModifiers.length > 0) {
            selectedSubModifier = m.subModifiers[0];
            quantity = m.subModifiers[0].quantity;
          }
        });
        let cntModifiers = 0;
        if (currentItem.modifiers && selectedSubModifier._id !== 0) {
          currentItem.modifiers.forEach((m) => {
            if (m._id !== 'xxx-xxx-xxx') {
              m.subModifiers.forEach((s) => {
                if (
                  s._id === selectedSubModifier._id &&
                  Number(s.quantity) > 0
                ) {
                  itemPrice = itemPrice + Number(s.quantity) * Number(s.price);
                } else if (Number(s.quantity) > 0) {
                  let matchedPrices = s.prices
                    ? s.prices.filter(
                        (f) =>
                          f._id === selectedSubModifier._id &&
                          f._vId === selectedSubModifier._vId
                      )
                    : [];
                  if (matchedPrices.length > 0) {
                    let matchedPrice = matchedPrices[0];
                    itemPrice =
                      itemPrice +
                      Number(s.quantity) * Number(matchedPrice.price);
                  }
                }
              });
            }
            if (m.subModifiers.some((s) => Number(s.quantity) > 0)) {
              cntModifiers = cntModifiers + 1;
            }
          });
          if (quantity > 0 && selectedSubModifier._id !== 0) {
            currentItem.selectedExtraIngredients &&
              currentItem.selectedExtraIngredients.forEach((item) => {
                if (item.quantity > 0) {
                  extraPrice =
                    extraPrice +
                    item.quantity * Number(this.getExtraPrice(item, false));
                }
              });
            itemPrice = extraPrice * quantity + itemPrice;
          }
          if (cntModifiers !== currentItem.modifiers.length) itemPrice = 0;
        }
      }
    }

    return { itemPrice, extraPrice };
  };

  updateItemExtrasPrice = () => {
    const { halfHalfSetup } = this.props;
    let quantity = 0;
    let extraPrice = 0;
    if (this.state.itemInProgress) {
      let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
      if (!currentItem.itemType || currentItem.itemType === 0) {
        currentItem.selectedSizes?.forEach((item) => {
          if (item.quantity > 0) {
            quantity = quantity + item.quantity;
          }
        });
        if (quantity > 0) {
          currentItem.selectedExtraIngredients?.forEach((item) => {
            if (item.quantity > 0) {
              extraPrice =
                extraPrice +
                item.quantity *
                  this.getExtraPrice(item, false) *
                  (halfHalfSetup?.chargeFullHalfExtraPrice
                    ? Math.max(quantity, 1)
                    : quantity);
            }
          });
        } else {
          currentItem.selectedExtraIngredients?.forEach((item) => {
            if (item.quantity > 0) {
              extraPrice =
                extraPrice + item.quantity * this.getExtraPrice(item, false);
            }
          });
        }
      } else {
        currentItem.modifiers?.forEach((m) => {
          if (m._id === 'xxx-xxx-xxx') {
            m.subModifiers.forEach((s) => {
              if (s.quantity > 0) {
                quantity = quantity + s.quantity;
              }
            });
          }
        });
        if (quantity > 0) {
          currentItem.selectedExtraIngredients?.forEach((item) => {
            if (item.quantity > 0) {
              extraPrice =
                extraPrice +
                item.quantity * this.getExtraPrice(item, false) * quantity;
            }
          });
        } else {
          currentItem.selectedExtraIngredients?.forEach((item) => {
            if (item.quantity > 0) {
              extraPrice =
                extraPrice + item.quantity * this.getExtraPrice(item, false);
            }
          });
        }
      }
    }
    this.setState({ extraPrice: extraPrice.toFixed(2) });
  };

  addRemoveIngredient = (actionType, item) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
    currentItem.selectedIngredients?.forEach((i) => {
      if (i._id === item._id) {
        if (actionType === 'removed') {
          i.inOrder = false;
        } else {
          i.inOrder = true;
        }
      }
    });
    this.setState({
      itemInProgress: currentItem,
      isIngredientsChanged: true,
    });
  };

  incrementExtrasItem = (extraItem) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
    currentItem.selectedExtraIngredients.forEach((item) => {
      if (item._id === extraItem._id) {
        if (item.quantity) {
          item.quantity = item.quantity + 1;
        } else {
          item.quantity = 1;
        }
      }
    });
    this.setState(
      {
        isExtraChanged: true,
        itemInProgress: currentItem,
      },
      () => {
        this.calulateIngredientListsAgain();
        this.updateItemExtrasPrice();
      }
    );
  };

  decrementExtraItem = (extraItem) => {
    let currentItem = JSON.parse(JSON.stringify(this.state.itemInProgress));
    let count = 0;
    currentItem.selectedExtraIngredients.forEach((item) => {
      if (item._id === extraItem._id) {
        if (item.quantity) {
          item.quantity = item.quantity - 1;
          if (item.quantity > 0) count = count + 1;
        }
      } else {
        if (item.quantity && item.quantity > 0) count = count + 1;
      }
    });
    this.setState(
      {
        ...this.state,
        isExtraChanged: count === 0 ? false : true,
        itemInProgress: currentItem,
      },
      () => {
        this.calulateIngredientListsAgain();
        this.updateItemExtrasPrice();
      }
    );
  };

  calulateIngredientListsAgain = () => {
    if (this.state.itemInProgress.selectedExtraIngredients) {
      //making specialised list of extras with item belongs to category of it, also contains logic of hide extras button if it's not avilable in list
      let myCategories = [];
      if (this.props.ingredientCategories) {
        myCategories = this.props.ingredientCategories;
      }
      if (myCategories.length > 0) {
        myCategories.forEach((item) => {
          if (this.state.itemInProgress.selectedIngredients) {
            item.ingredientList =
              this.state.itemInProgress.selectedIngredients.filter(
                (obj) => obj.ingredientCategory === item._id
              );
          }
          if (this.state.itemInProgress.selectedExtraIngredients) {
            item.extraList =
              this.state.itemInProgress.selectedExtraIngredients.filter(
                (obj) => obj.ingredientCategory === item._id
              );
            item.extraList = orderBy(
              item.extraList,
              ['agregateSales'],
              ['desc']
            );
          }
        });

        myCategories.forEach((category) => {
          category?.extraList?.forEach((item) => {
            let finalPrices = [];

            item.prices?.forEach((pp) => {
              if (pp.isSelected) {
                let pObj = {
                  _id: pp._id,
                  name: pp.name,
                  price: pp.price,
                  isVariant: false,
                  _vId: 'xxx',
                  _vName: 'xxx',
                };
                finalPrices.push(pObj);

                pp.variants?.map((vv) => {
                  if (vv.isSelected) {
                    let vObj = {
                      _id: pp._id,
                      name: pp.name,
                      isVariant: true,
                      _vId: vv._id,
                      _vName: vv.name,
                      price: Number(
                        Number(pp.price) + Number(vv.price)
                      ).toFixed(2),
                    };
                    finalPrices.push(vObj);
                  }
                  return vv;
                });
              }
            });

            item.finalPrices = finalPrices;
          });
        });

        myCategories = myCategories.filter(
          (item) => item.extraList && item.extraList.length > 0
        );

        let allExtrasList = [];
        myCategories.forEach((category) => {
          category.extraList.forEach((item) => {
            allExtrasList.push(item);
          });
        });

        allExtrasList = sortBy(allExtrasList, ['agregateSales']).reverse();

        var categoryObj = {};

        allExtrasList.forEach(
          (extraCategory) => (categoryObj[extraCategory.ingredientCategory] = 0)
        );

        var finalArray = [];

        allExtrasList.forEach((menuItem) => {
          if (categoryObj[menuItem.ingredientCategory] < 8) {
            finalArray.push(menuItem);
            categoryObj[menuItem.ingredientCategory] =
              categoryObj[menuItem.ingredientCategory] + 1;
          }
        });

        for (let i = 2; i < finalArray.length; i++) {
          const current = finalArray[i];
          if (
            current.ingredientCategory ===
              finalArray[i - 1].ingredientCategory &&
            current.ingredientCategory === finalArray[i - 2].ingredientCategory
          ) {
            // find next valid element
            let j = i + 1;
            while (
              j < finalArray.length - 1 &&
              current.ingredientCategory === finalArray[j].ingredientCategory
            ) {
              j++;
            }
            if (j < finalArray.length - 1) {
              let replace = finalArray.splice(j, 1);
              finalArray.splice(i, 0, replace[0]);
            }
          }
        }
        var top8popular = finalArray.slice(0, 8);

        this.setState({
          ingredientCategories: myCategories,
          popularIngredients: top8popular.filter(Boolean),
        });
      }
    }
  };

  toggleExtraScreen = () => {
    this.setState({ toggleExtrasSection: true, toggleTransition: true });
  };

  closeExtraSection = () => {
    firebaseAnalyticsNO.logEvent(eventNames.MENU_ITEM_CUSTOMIZED, {
      type: 'add_extras',
    });
    let extrasForOrderItem = JSON.parse(
      JSON.stringify(this.state.orderItem.selectedExtraIngredients)
    );
    let ingredientsForprogressItem = JSON.parse(
      JSON.stringify(this.state.itemInProgress.selectedExtraIngredients)
    );
    let addedExtraCount = 0;
    ingredientsForprogressItem.forEach((m) => {
      if (m.quantity && Number(m.quantity) > 0) {
        addedExtraCount = addedExtraCount + Number(m.quantity);
      }
    });
    if (addedExtraCount === 0) {
      this.setState(
        {
          toggleTransition: false,
          toggleExtrasSection: false,
          isExtraChanged: false,
          addedExtraCount: 0,
          orderItem: {
            ...this.state.orderItem,
            selectedExtraIngredients: ingredientsForprogressItem,
          },
        },
        () => {
          this.calulateIngredientListsAgain();
        }
      );
    } else {
      this.setState(
        {
          toggleTransition: false,
          toggleExtrasSection: false,
          isExtraChanged: false,
          itemInProgress: {
            ...this.state.itemInProgress,
            selectedExtraIngredients: extrasForOrderItem,
          },
        },
        () => {
          this.calulateIngredientListsAgain();
        }
      );
    }
  };

  closeIngredientSection = () => {
    let ingredientsForOrderItem = JSON.parse(
      JSON.stringify(this.state.orderItem.selectedIngredients)
    );
    this.setState(
      {
        toggleIngredientsSection: false,
        isIngredientsChanged: false,
        itemInProgress: {
          ...this.state.itemInProgress,
          selectedIngredients: ingredientsForOrderItem,
        },
      },
      () => {
        this.calulateIngredientListsAgain();
      }
    );
  };

  openIngredientSection = () => {
    this.setState({ toggleIngredientsSection: true });
  };

  addIngredientToItem = () => {
    let removedIngredients =
      this.state.itemInProgress.selectedIngredients &&
      this.state.itemInProgress.selectedIngredients.length > 0
        ? JSON.parse(
            JSON.stringify(this.state.itemInProgress.selectedIngredients)
          )
        : [];
    let removedIngCount = 0;
    removedIngredients.forEach((m) => {
      if (!m.inOrder) {
        removedIngCount = removedIngCount + 1;
      }
    });
    if (removedIngCount) {
      firebaseAnalyticsNO.logEvent(eventNames.MENU_ITEM_CUSTOMIZED, {
        type: 'edited_ingredients',
      });
    }
    this.setState(
      {
        toggleIngredientsSection: false,
        removedIngCount,
        isIngredientsChanged: false,
        orderItem: {
          ...this.state.orderItem,
          selectedIngredients: removedIngredients,
        },
      },
      () => {
        this.calulateIngredientListsAgain();
      }
    );
  };

  addExtasInItem = () => {
    let editedExtras =
      this.state.itemInProgress.selectedExtraIngredients &&
      this.state.itemInProgress.selectedExtraIngredients.length > 0
        ? JSON.parse(
            JSON.stringify(this.state.itemInProgress.selectedExtraIngredients)
          )
        : [];
    let addedExtraCount = 0;
    editedExtras.forEach((m) => {
      if (m.quantity && Number(m.quantity) > 0) {
        addedExtraCount = addedExtraCount + Number(m.quantity);
      }
    });
    this.setState(
      {
        toggleTransition: false,
        toggleExtrasSection: false,
        addedExtraCount,
        isExtraChanged: false,
        orderItem: {
          ...this.state.orderItem,
          selectedExtraIngredients: editedExtras,
        },
      },
      () => {
        this.calulateIngredientListsAgain();
      }
    );
  };

  addToOrder = () => {
    let itemObj = JSON.parse(JSON.stringify(this.state.itemInProgress));
    let quantity = 0;

    if (!itemObj.itemType || itemObj.itemType === 0) {
      itemObj.selectedSizes = itemObj.selectedSizes.filter((s) => {
        if (s.variants) {
          s.variants = s.variants.filter(
            (v) => v.quantity && Number(v.quantity) > 0
          );
        }

        if (s.quantity && Number(s.quantity) > 0) {
          quantity += s.quantity;
        }

        return s.quantity && Number(s.quantity) > 0;
      });
    } else {
      // Fillup data
      itemObj.selectedModifiers.forEach((m) => {
        let matchingModifiers = itemObj?.modifiers?.filter(
          (fm) => fm._id === m._id
        );

        if (matchingModifiers?.length > 0) {
          const isSingleSize = m.subModifiers?.length === 1;
          let matchedModifier = matchingModifiers[0];
          m.subModifiers.forEach((s) => {
            let matchingSubModifiers = matchedModifier?.subModifiers.filter(
              (fs) => fs._id === s._id && fs.quantity > 0
            );
            if (matchingSubModifiers.length > 0) {
              let matchedSubModifier = matchingSubModifiers[0];
              if (m._id === itemObj.baseModifierId) {
                s.quantity = matchedSubModifier.quantity;
                s.isSelected = matchedSubModifier.quantity > 0 ? true : false;

                s.variants?.forEach((v) => {
                  if (v._id === matchedSubModifier._vId) {
                    v.quantity = matchedSubModifier.quantity;
                    v.isSelected = true;
                  } else {
                    v.quantity = 0;
                    v.isSelected = false;
                  }
                });
              } else {
                s.isSelected = true;

                s.prices?.forEach((p) => {
                  if (p?._id === this.sBaseSubModifier?._id) {
                    p.isSelected = true;
                    p.quantity = matchedSubModifier.quantity;

                    p.variants?.forEach((v) => {
                      if (v._id === matchedSubModifier._vId) {
                        v.quantity = matchedSubModifier.quantity;
                        v.isSelected = true;
                      } else {
                        v.quantity = 0;
                        v.isSelected = false;
                      }
                    });
                  } else {
                    p.isSelected = false;
                    p.quantity = 0;
                  }
                });
              }
            } else {
              s.isSelected = false;
            }
            s.isSingleSize = isSingleSize;
          });
        }
      });

      // Step 1: Remove not selected sub modifiers
      itemObj.selectedModifiers.forEach((m) => {
        m.subModifiers = m.subModifiers.filter((s) => s.isSelected === true);
      });

      // Step 2: Cleanup submodifiers
      itemObj.selectedModifiers.forEach((m) => {
        m.subModifiers = m.subModifiers.map((s) => {
          if (m._id === itemObj.baseModifierId) {
            s.variants = s.variants?.filter((f) => f.isSelected);
          } else {
            s.prices = s.prices.filter((f) => f.isSelected);
            s.prices.forEach((p) => {
              if (p.variants) {
                p.variants = p.variants.filter((f) => f.isSelected);
              }
            });
          }
          return s;
        });
        if (m._id === itemObj.baseModifierId)
          itemObj.selectedSizes = m.subModifiers;
      });

      // Step 3: Composite name for size
      let subsMs = [];
      itemObj.selectedModifiers?.forEach((m) => {
        subsMs = [...subsMs, ...m.subModifiers];
      });
      itemObj.selectedSizes.forEach((s) => {
        quantity += s.quantity;
        if (itemObj.isMultiselectAvailable) {
          s.cName = subsMs
            .filter(
              (f) =>
                f.name !== s.name &&
                !f.name?.toLowerCase()?.split(' ')?.includes('no')
            )
            .map((m) => m.name)
            .join(',');
        } else {
          s.cName = subsMs
            .filter((f) => f.name !== s.name)
            .map((m) => m.name)
            .join(',');
        }
      });
    }

    // Extra Ingredients
    if (itemObj.selectedExtraIngredients) {
      itemObj.selectedExtraIngredients =
        itemObj.selectedExtraIngredients.filter(
          (m) => m.quantity && Number(m.quantity) > 0
        );
      if (itemObj.itemType === 1) {
        itemObj.selectedExtraIngredients = itemObj.selectedExtraIngredients.map(
          (m) => {
            m.finalPrices?.forEach((p) => {
              if (
                p._id === this.sBaseSubModifier._id &&
                p._vId === this.sBaseSubModifier._vId
              ) {
                m.price = p.price;
                m.sizeId = p._id;
                m.variants = p._id;
              }
            });

            m.finalPrices && delete m.finalPrices;
            m.prices && delete m.prices;
            return m;
          }
        );
      } else {
        itemObj.selectedExtraIngredients = itemObj.selectedExtraIngredients.map(
          (m) => {
            let price = 0;
            itemObj.selectedSizes.forEach((s) => {
              m.prices?.forEach((p) => {
                if (p._id === s._id) {
                  price = price + Number(p.price);
                  m.sizeId = s._id;
                  if (s.variants && p.variants) {
                    s.variants.forEach((v) => {
                      if (Number(v.quantity) > 0) {
                        let matchingVariants = p.variants.filter(
                          (f) => f._id === v.Id
                        );
                        if (matchingVariants.length > 0) {
                          price = price + Number(matchingVariants[0].price);
                          m.variantId = matchingVariants[0]._id;
                        }
                      }
                    });
                  }
                }
              });
            });
            m.price = price;
            m.finalPrices && delete m.finalPrices;
            m.prices && delete m.prices;
            return m;
          }
        );
      }
    }

    // Default Ingredients
    if (itemObj.selectedIngredients) {
      let ings = itemObj.selectedIngredients;
      itemObj.selectedIngredients = ings.filter((m) => m.inOrder);
      itemObj.removedIngredients = ings.filter((m) => !m.inOrder);
    }

    // Resetting Item view to normacy
    delete itemObj.showPrice;
    delete itemObj.modifiers;
    delete itemObj.orders;
    delete itemObj.orderCount;

    // Add general additional fields
    const { itemPrice, extraPrice } = this.getItemPrice();
    itemObj.itemPrice = itemPrice - extraPrice;
    itemObj.extraPrice = extraPrice;
    itemObj.payablePrice = itemPrice;
    itemObj.quantity = quantity > 1 ? quantity : 1;

    if (itemObj.itemVariants) delete itemObj.itemVariants;

    this.props.addToOrder(itemObj);
  };

  resetItemView = () => {
    let ingredientCategories = [],
      popularIngredients = [];
    let itemVariants = [];
    if (
      this.state.ingredientCategories &&
      this.state.ingredientCategories.length > 0
    ) {
      ingredientCategories = JSON.parse(
        JSON.stringify(this.state.ingredientCategories)
      );
      ingredientCategories.forEach((item) => {
        item.ingredientList =
          item.ingredientList && item.ingredientList.length > 0
            ? item.ingredientList.map((obj) => {
                obj.inOrder = true;
                return obj;
              })
            : [];
        item.extraList =
          item.extraList && item.extraList.length > 0
            ? item.extraList.filter((obj) => {
                item.quantity = 0;
                return obj;
              })
            : [];
      });
    }
    if (this.state.popularIngredients) {
      popularIngredients = JSON.parse(
        JSON.stringify(this.state.popularIngredients)
      );
      popularIngredients.forEach((m) => {
        if (m) {
          m.quantity = 0;
          return m;
        }
      });
    }
    let orderObj = JSON.parse(JSON.stringify(this.state.orderItem));
    if (orderObj.selectedIngredients) {
      orderObj.selectedIngredients = orderObj.selectedIngredients.map((s) => {
        s.inOrder = true;
        return s;
      });
      orderObj.removedIngredients = [];
    }
    if (orderObj.selectedExtraIngredients) {
      orderObj.selectedExtraIngredients = orderObj.selectedExtraIngredients.map(
        (s) => {
          s.quantity = 0;
          return s;
        }
      );
    }
    if (orderObj.selectedModifiers && orderObj.itemType !== 0) {
      let modifiers = [];
      let maxModifierDisplayOrder = 0;
      orderObj.selectedModifiers.forEach((m) => {
        let obj = {
          _id: m._id,
          name: m.name,
          sName: '',
          displayOrder: m.displayOrder,
          subModifiers: [],
        };
        if (m._id === orderObj.baseModifierId && m.subModifiers) {
          m.subModifiers.forEach((s) => {
            let sObj = {
              _id: s._id,
              name: s.name,
              isVariant: false,
              _vId: 'xxx',
              _vName: 'xxx',
              price: s.price,
              isOptionAvailable: true,
            };
            obj.subModifiers.push(sObj);

            s.variants?.forEach((v) => {
              if (v.isSelected) {
                let vObj = {
                  _id: s._id,
                  name: s.name,
                  isVariant: true,
                  _vId: v._id,
                  _vName: v.name,
                  price: Number(Number(s.price) + Number(v.price)).toFixed(2),
                  isOptionAvailable: true,
                };
                obj.subModifiers.push(vObj);
              }
            });
          });
        } else {
          m.subModifiers?.forEach((s) => {
            let sObj = {
              _id: s._id,
              name: s.name,
              isVariant: false,
              _vId: 'xxx',
              _vName: 'xxx',
              prices: [],
              isOptionAvailable: true,
            };

            s.prices?.forEach((pp) => {
              let pObj = {
                _id: pp._id,
                name: pp.name,
                price: pp.price,
                isVariant: false,
                _vId: 'xxx',
                _vName: 'xxx',
                isSelected: pp.isSelected,
              };
              sObj.prices.push(pObj);

              pp.variants?.forEach((vv) => {
                if (vv.isSelected) {
                  let vObj = {
                    _id: pp._id,
                    name: pp.name,
                    isVariant: true,
                    _vId: vv._id,
                    _vName: vv.name,
                    price: Number(Number(pp.price) + Number(vv.price)).toFixed(
                      2
                    ),
                    isSelected: pp.isSelected,
                  };
                  sObj.prices.push(vObj);
                }
              });
            });

            obj.subModifiers.push(sObj);
          });
        }
        if (maxModifierDisplayOrder < obj?.displayOrder) {
          maxModifierDisplayOrder = obj.displayOrder;
        }
        modifiers.push(obj);
      });

      modifiers.push({
        subModifiers: [],
        name: 'Customise',
        isCustomiseSection: true,
        displayOrder: maxModifierDisplayOrder + 1,
        _id: 'xxx-xxx-xxx',
      });

      modifiers.forEach((m) => {
        var ele = document.getElementsByName(m.name);
        for (var i = 0; i < ele.length; i++) ele[i].checked = false;
      });

      modifiers = orderBy(modifiers, 'displayOrder');

      if (!this.state.itemInProgress.isSoldOut) {
        modifiers.forEach((m) => {
          if (m._id !== 'xxx-xxx-xxx' && m.subModifiers.length === 1) {
            m.subModifiers.forEach((s) => {
              s.quantity =
                this.props.rightSideViewVisibilityIndex ===
                VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
                  ? 0.5
                  : 1;
              m.sName = `${s.name}`;
            });
          } else {
            m.subModifiers?.forEach((s) => {
              s.quantity = 0;
            });
          }
        });
      }

      let lastIndexNeedsprocessing = -1;
      modifiers.forEach((modifier, index) => {
        let matchingSubModifiers = modifier.subModifiers.filter(
          (s) => s.quantity > 0
        );
        if (
          matchingSubModifiers.length <= 0 &&
          lastIndexNeedsprocessing === -1
        ) {
          lastIndexNeedsprocessing = index;
        }
      });

      modifiers.forEach((m, index) => {
        if (index === lastIndexNeedsprocessing) {
          m.isOpen = true;
          m.isDisabled = false;
        } else {
          m.isOpen = false;
          m.isDisabled = index > lastIndexNeedsprocessing ? true : false;
        }
      });

      orderObj.modifiers = modifiers;
    }

    orderObj.selectedSizes?.forEach((item) => {
      item.variants?.forEach((v) => {
        v.quantity = 0;
      });

      item.isDisabled = false;
    });

    itemVariants = JSON.parse(JSON.stringify(this.state.itemVariants));
    itemVariants.forEach((v) => {
      v.isClicked = false;
    });

    this.setState(
      {
        toggleTransition: false,
        toggleExtrasSection: false,
        addedExtraCount: 0,
        removedIngCount: 0,
        ingredientCategories,
        popularIngredients,
        itemInProgress: { ...orderObj },
        orderItem: { ...orderObj },
        itemVariants,
      },
      () => {
        this.calulateIngredientListsAgain();
      }
    );
  };

  closeItem = () => {
    this.props.closeItem();
  };

  setModifier = (
    _e,
    mIndex,
    modifier,
    subModifier,
    activeModifiers,
    checked
  ) => {
    let itemInProgress = {
      ...this.state.itemInProgress,
      modifiers: activeModifiers,
    };
    if (modifier.isMultiselect) {
      let matchedAndSelected = false;
      let groupInfo = this.state.groupsMultiModifiers[modifier._id];
      if (groupInfo) {
        const groupIds = [modifier._id, ...groupInfo];

        for (let i = 0; i < groupIds.length; i++) {
          const m = itemInProgress.modifiers.find(
            (mod) => mod._id === groupIds[i]
          );
          if (m && m.subModifiers) {
            if (!checked) {
              const matchingSubModifier = m.subModifiers.find(
                (s) => s.quantity === 1 && s.name === subModifier.name
              );
              const matchingNoSubModifier = m.subModifiers.find((s) =>
                s.name?.toLowerCase()?.split(' ')?.includes('no')
              );
              if (matchingSubModifier && matchingNoSubModifier) {
                matchingSubModifier.quantity = 0;
                matchingNoSubModifier.quantity = 1;
                matchedAndSelected = true;
                break;
              }
            } else {
              const hasNonNoSelected = m.subModifiers.some(
                (s) =>
                  s.quantity === 1 &&
                  !s.name?.toLowerCase()?.split(' ')?.includes('no')
              );
              if (!hasNonNoSelected) {
                const matchingSubModifier = m.subModifiers.find(
                  (s) => s.name === subModifier.name
                );
                const matchingNoSubModifier = m.subModifiers.find((s) =>
                  s.name?.toLowerCase()?.split(' ')?.includes('no')
                );
                if (matchingSubModifier && matchingNoSubModifier) {
                  matchingNoSubModifier.quantity = 0;
                  matchingSubModifier.quantity = 1;
                  matchedAndSelected = true;
                  break;
                }
              }
            }
          }
        }
      }
      if (matchedAndSelected) {
        const selectedSubModifier = modifier.subModifiers.find(
          (s) => s.name?.toLowerCase() === subModifier.name?.toLowerCase()
        );
        if (selectedSubModifier) {
          selectedSubModifier.isMultiselectSelected = checked;
        }
      }
    } else {
      itemInProgress.modifiers.forEach((m, index) => {
        if (m._id === modifier._id) {
          if (m.subModifiers) {
            m.subModifiers.forEach((s) => {
              if (s._id === subModifier._id && s._vId === subModifier._vId) {
                s.quantity =
                  this.props.rightSideViewVisibilityIndex ===
                  VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
                    ? 0.5
                    : 1;
                modifier.sName = `${subModifier.name}`;
              } else {
                s.quantity = 0;
              }
            });
          }
        } else {
          //No action for other modifiers
        }

        if (index <= mIndex + 1) {
          m.isDisabled = false;
        }
      });
    }

    if (modifier._id === itemInProgress.baseModifierId) {
      //Check other modifiers for their availability
      this.state.itemInProgress.modifiers.forEach((m) => {
        if (m._id !== itemInProgress.baseModifierId) {
          m.subModifiers.forEach((s) => {
            if (s.prices) {
              let matchingPrice = s.prices.filter(
                (p) => p._id === subModifier._id && p._vId === subModifier._vId
              );
              if (matchingPrice.length > 0 && matchingPrice[0].isSelected) {
                s.isOptionAvailable = true;
              } else {
                s.isOptionAvailable = false;
                s.quantity = 0;
              }
            }
          });
          m.sName = undefined;
        }
      });
    }

    let matchingBaseModifiers = this.state.itemInProgress.modifiers.filter(
      (p) => p._id === itemInProgress.baseModifierId
    );
    let matchedModifier = matchingBaseModifiers
      ? matchingBaseModifiers[0]
      : undefined;
    let matchedSubModifiers = matchedModifier?.subModifiers.filter(
      (f) => f.quantity > 0
    );

    itemInProgress.modifiers.forEach((m) => {
      if (m._id === 'xxx-xxx-xxx') {
        m.subModifiers = matchedSubModifiers;
        this.sBaseSubModifier = matchedSubModifiers[0];
      }
    });

    const newModifierIds = itemInProgress.modifiers.map((i) => i._id);

    const newModifiers = [
      ...itemInProgress.modifiers,
      ...this.state.itemInProgress.modifiers.filter(
        (i) => !newModifierIds.includes(i._id)
      ),
    ];

    let filteredNewModifiers = orderBy(newModifiers, 'displayOrder').filter(
      (m) =>
        !(
          itemInProgress.baseModifierId === m._id &&
          m?.subModifiers?.length <= 1
        ) &&
        (m?.isCustomiseSection ||
          (m?.subModifiers.length > 0 &&
            m?.subModifiers?.some((n) => n.isOptionAvailable)))
    );

    if (filteredNewModifiers[mIndex]) {
      filteredNewModifiers[mIndex].isOpen = false;

      let mIndexNext = mIndex + 1;

      const subModifiersAvailabilityCount = modifier?.subModifiers?.filter(
        (f) => f.isOptionAvailable && f.isDisplayForSpecialOrHalfHalf
      ).length;

      if (
        filteredNewModifiers[mIndexNext]?._id ===
          itemInProgress.baseModifierId &&
        subModifiersAvailabilityCount <= 1
      ) {
        mIndexNext++;
      }

      if (filteredNewModifiers[mIndexNext]) {
        filteredNewModifiers[mIndexNext].isOpen = true;
      }
    }

    this.setState({
      itemInProgress: {
        ...itemInProgress,
        modifiers: newModifiers,
      },
    });
  };

  handleTriggerClick = (position, activeModifiers) => () => {
    const newModifiers = activeModifiers.map((modifier, index) => ({
      ...modifier,
      isOpen: index === position,
    }));

    const newModifierIds = newModifiers.map((i) => i._id);

    this.setState({
      itemInProgress: {
        ...this.state.itemInProgress,
        modifiers: [
          ...this.state.itemInProgress.modifiers.filter(
            (i) => !newModifierIds.includes(i._id)
          ),
          ...newModifiers,
        ],
      },
    });
  };

  getModifierPrice = (subModifier, modifier) => {
    const { storeConfig, halfHalfSetup } = this.props;
    let price = undefined;
    if (modifier.isCustomiseSection) {
      price = subModifier.price;
      if (
        this.props.rightSideViewVisibilityIndex ===
          VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW &&
        !halfHalfSetup?.chargeFullHalfExtraPrice
      )
        price = Number(Number(price) / 2).toFixed(2);
      else price = Number(price).toFixed(2);
      return `${storeConfig.currency}${price}`;
    } else {
      if (modifier._id === this.state.itemInProgress.baseModifierId) {
        price = subModifier.price;
        if (
          this.props.rightSideViewVisibilityIndex ===
            VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW &&
          !halfHalfSetup?.chargeFullHalfExtraPrice
        )
          price = (Number(price) / 2).toFixed(2);
        else price = Number(price).toFixed(2);
        return `${storeConfig.currency}${price}`;
      } else {
        let matchingBaseModifiers = this.state.itemInProgress.modifiers.filter(
          (p) => {
            return this.state.itemInProgress.baseModifierId === p._id;
          }
        );
        if (matchingBaseModifiers.length > 0) {
          let matchedModifier = matchingBaseModifiers
            ? matchingBaseModifiers[0]
            : undefined;
          let matchedSubModifiers = matchedModifier?.subModifiers.filter(
            (f) => {
              return f.quantity > 0;
            }
          );
          if (matchedSubModifiers.length > 0) {
            let matchedSubModifier = matchedSubModifiers[0];
            let matchedPrices = subModifier.prices.filter((p) => {
              return (
                matchedSubModifier._id === p._id &&
                matchedSubModifier._vId === p._vId
              );
            });
            if (
              matchedPrices.length > 0 &&
              Number(matchedPrices[0].price) > 0
            ) {
              if (
                this.props.rightSideViewVisibilityIndex ===
                  VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW &&
                !halfHalfSetup?.chargeFullHalfExtraPrice
              )
                price = (Number(matchedPrices[0].price) / 2).toFixed(2);
              else price = Number(matchedPrices[0].price).toFixed(2);
              return `+${storeConfig.currency}${price}`;
            }
          }
        }
      }
    }
  };

  getExtraPrice = (item, forDisplay) => {
    const { rightSideViewVisibilityIndex, halfHalfSetup } = this.props;
    if (
      !this.state.itemInProgress.itemType ||
      this.state.itemInProgress.itemType === 0
    ) {
      if (item.prices && item.prices.length > 0) {
        if (this.sSize) {
          let matchedPrices = item.prices.filter((f) => {
            return f._id === this.sSize._id && f.isSelected === true;
          });
          if (matchedPrices.length > 0) {
            let matchedPrice = matchedPrices[0];
            let price = 0;
            price = Number(matchedPrice.price);
            if (matchedPrice.variants && this.state.itemVariants) {
              matchedPrice.variants.forEach((v) => {
                let matchedPs = this.state.itemVariants.filter(
                  (vv) => vv._id === v._id && vv.isClicked
                );
                if (matchedPs.length > 0) {
                  price = price + Number(v.price);
                }
              });
            }
            if (
              forDisplay &&
              rightSideViewVisibilityIndex ===
                VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW &&
              !halfHalfSetup?.chargeFullHalfExtraPrice
            )
              return Number(price) / 2;
            else return price;
          } else return 0;
        } else {
          if (
            forDisplay &&
            rightSideViewVisibilityIndex ===
              VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
          )
            return Number(item.prices[0].price);
          else return item.prices[0].price;
        }
      } else return item.price;
    } else {
      if (item.finalPrices && this.sBaseSubModifier) {
        let matchedPrices = item.finalPrices.filter((h) => {
          return (
            h._id === this.sBaseSubModifier._id &&
            h._vId === this.sBaseSubModifier._vId
          );
        });
        if (matchedPrices.length > 0) {
          if (
            forDisplay &&
            this.props.rightSideViewVisibilityIndex ===
              VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW &&
            !halfHalfSetup?.chargeFullHalfExtraPrice
          )
            return Number(matchedPrices[0].price) / 2;
          else return matchedPrices[0].price;
        } else return item.price;
      } else return 0;
    }
  };

  getSizePrice = (size) => {
    let price = 0;
    if (size.price) {
      price = price + Number(size.price);
    }
    if (size.variants && size.variants.length > 0) {
      size.variants.forEach((v) => {
        let variants = this.state.itemVariants.filter((f) => {
          return f._id === v._id && f.isClicked === true;
        });
        if (variants.length > 0) price = price + Number(v.price);
      });
    }
    return price.toFixed(2);
  };

  checkAvailability = (item) => {
    const { itemInProgress, soldOutMenuItems } = this.props;
    const { itemVariants } = this.state;

    let variants = [...itemVariants];

    const selectedVariants = variants.filter((variant) => {
      return variant.isClicked === true;
    });

    if (Array.isArray(soldOutMenuItems)) {
      return selectedVariants.length
        ? selectedVariants.every(
            (v) =>
              soldOutMenuItems.findIndex(
                (k) =>
                  k.menuItemId === itemInProgress._id &&
                  k.smId === item._id &&
                  k.variantId === v._id
              ) !== -1
          )
        : soldOutMenuItems.findIndex(
            ({ menuItemId, smId, variantId }) =>
              menuItemId === itemInProgress?._id &&
              smId === item?._id &&
              !variantId
          ) !== -1;
    }

    return true;
  };

  checkModifierAvailability = (subModifier) => {
    const result =
      this.props.soldOutMenuItems.findIndex(
        (l) =>
          l.menuItemId === this.state.itemInProgress._id &&
          l.smId === subModifier._id &&
          ((subModifier._vId === 'xxx' && l.variantId === null) ||
            l.variantId === subModifier._vId)
      ) === -1;

    return result;
  };

  render() {
    const { storeConfig } = this.props;
    const { itemVariants } = this.state;

    let sectionStyle = {
      backgroundImage: `url(${this.props.imageUrl})`,
    };

    const { itemInProgress } = this.state;
    const itemPrice = this.getItemPrice().itemPrice;

    let itemViewClass = classNames({
      itemDet: true,
      singleSize: this.state.singleSize,
      orderBtnP: itemPrice > 0,
      temp: this.state.movePriceSection,
      modiferSection:
        itemInProgress?.itemType && itemInProgress?.itemType === 1,
    });

    const clickedItemVariants = itemVariants.filter((v) => v.isClicked);

    const isMultiSelection = itemInProgress?.isMultiselectAvailable;
    const accordianMainContainerStyle = isMultiSelection
      ? {
          margin: '0 -20px',
          maxHeight: 'calc(60vh - 78px)',
          overflow: 'auto',
        }
      : { margin: '0 -20px' };

    return (
      <div
        className={'itemView'}
        style={{
          marginTop: '0px',
          paddingTop: '0px',
          top: '0px',
          height: '100%',
        }}
      >
        <CloseButtonCWUI
          onClick={() => this.closeItem()}
          extendedClassName='absolute z-100 top-3.25  sm:top-3.25 left-5.25'
        />

        <div className='imgView' style={sectionStyle}>
          <img src={this.props.imageUrl} alt='' />
        </div>
        <div className={itemViewClass}>
          {this.state.isItemInProgressLoading ? (
            <ItemSkeleton name={this.props.name} />
          ) : (
            <>
              <div
                style={{
                  display: `flex`,
                  alignItems: 'center',
                  justifyContent: `space-between`,
                  marginBottom: 8,
                }}
              >
                <span
                  className='nameView text-capitalize'
                  style={{ marginBottom: 0 }}
                >
                  {this.props.name}
                </span>
                <div
                  style={{
                    display: `flex`,
                    flexFlow: `row wrap`,
                    justifyContent: `space-around`,
                  }}
                >
                  {this.props.openedFrom !== 'half' &&
                    itemInProgress &&
                    (!itemInProgress.itemType ||
                      (itemInProgress.itemType &&
                        itemInProgress.itemType === 0)) &&
                    this.state.itemVariants.length > 0 && (
                      <>
                        {this.state.itemVariants.map((v) => (
                          <div
                            key={v._id}
                            className='gluten-free-half-special-view'
                          >
                            <div
                              className={`${v.isClicked} visible-xs visible-sm visible-md visible-lg`}
                            >
                              <ButtonUI
                                btnType='button'
                                rounded='full'
                                variant='outlined'
                                shadow='sm'
                                leftIcon={
                                  v.isClicked ? (
                                    <CheckIcon className='w-[20px]' />
                                  ) : (
                                    <PlusIcon className='w-[20px]' />
                                  )
                                }
                                onClick={() => this.toggleVariant(v)}
                                extendedClassName='glutenFree text-capitalize'
                              >
                                {v.name.length > 12
                                  ? v.name.substr(0, 10) + '..'
                                  : v.name}
                              </ButtonUI>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                </div>
              </div>
              <span className='ingView text-capitalize'>
                {this.props.description}
              </span>
              <div
                style={{
                  flexGrow: 1,
                  margin:
                    (this.props.openedFrom === 'special' ||
                      this.props.openedFrom === 'half') &&
                    itemInProgress?.itemType !== 0
                      ? '16px -26px 0'
                      : '',
                }}
                className={
                  itemInProgress &&
                  (!itemInProgress.itemType ||
                    (itemInProgress.itemType && itemInProgress.itemType === 0))
                    ? 'quantityView active'
                    : 'quantityView'
                }
              >
                {(this.props.rightSideViewVisibilityIndex ===
                  VISIBILITY_INDEX_TYPE.ORDER_VIEW ||
                  this.props.rightSideViewVisibilityIndex ===
                    VISIBILITY_INDEX_TYPE.NONE) && (
                  <React.Fragment>
                    {itemInProgress &&
                    itemInProgress.itemType &&
                    itemInProgress.itemType === 1 ? (
                      <div
                        style={accordianMainContainerStyle}
                        className='accordion-main-container'
                      >
                        {itemInProgress.modifiers &&
                          itemInProgress.modifiers.length > 0 && (
                            <React.Fragment>
                              <Accordion
                                easing={'linear'}
                                transitionTime={240}
                                className={isMultiSelection ? 'h-unset' : ''}
                              >
                                {orderBy(
                                  itemInProgress.modifiers,
                                  'displayOrder'
                                )
                                  .filter(
                                    (m) =>
                                      !(
                                        itemInProgress.baseModifierId ===
                                          m._id && m?.subModifiers?.length <= 1
                                      ) &&
                                      (m?.isCustomiseSection ||
                                        (m?.subModifiers.length > 0 &&
                                          m?.subModifiers?.some(
                                            (n) => n.isOptionAvailable
                                          )))
                                  )
                                  .map((modifier, mIndex, activeModifiers) => {
                                    if (modifier?.isHiddenMultiselect) {
                                      return null;
                                    }
                                    return (
                                      <Fragment
                                        key={`${Math.random().toFixed(
                                          6
                                        )}-${mIndex}`}
                                      >
                                        <div
                                          data-trigger={
                                            modifier.sName
                                              ? `${modifier.sName}`
                                              : `${modifier.name}`
                                          }
                                          data-trigger-when-open={
                                            modifier.sName
                                              ? `${modifier.sName}`
                                              : modifier.isCustomiseSection
                                              ? `${modifier.name}`
                                              : `Select ${modifier.name}`
                                          }
                                          // data-trigger-disabled={modifier.isDisabled}
                                          data-trigger-isopen={
                                            isMultiSelection
                                              ? true
                                              : modifier.isOpen
                                          }
                                          className='text-capitalize'
                                        >
                                          {orderBy(
                                            modifier.subModifiers,
                                            'displayOrder'
                                          )
                                            .filter(
                                              (f) =>
                                                f.isOptionAvailable &&
                                                !f?.isHiddenMultiselect
                                            )
                                            .map((subModifier, sIndex) => {
                                              return (
                                                <React.Fragment key={sIndex}>
                                                  {!modifier.isCustomiseSection ? (
                                                    <React.Fragment>
                                                      <div
                                                        className='subModifer-container'
                                                        onClick={(e) => {
                                                          const isAvailable =
                                                            this.checkModifierAvailability(
                                                              subModifier
                                                            );

                                                          if (isAvailable) {
                                                            this.setModifier(
                                                              e,
                                                              mIndex,
                                                              modifier,
                                                              subModifier,
                                                              activeModifiers,
                                                              !(
                                                                subModifier.quantity >
                                                                  0 ||
                                                                subModifier.isMultiselectSelected
                                                              )
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: 'flex',
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              fontWeight: '400',
                                                              fontSize:
                                                                '1.95em',
                                                              padding:
                                                                '0.5em 0',
                                                            }}
                                                            className='text-capitalize'
                                                          >
                                                            {subModifier.isVariant
                                                              ? `${subModifier.name} - ${subModifier._vName}`
                                                              : subModifier.name}
                                                          </p>
                                                          <div>
                                                            <p
                                                              style={{
                                                                fontWeight:
                                                                  '300',
                                                                fontSize:
                                                                  '1.95em',
                                                                padding:
                                                                  '0.5em 0',
                                                                marginLeft:
                                                                  '30px',
                                                              }}
                                                            >
                                                              {this.getModifierPrice(
                                                                subModifier,
                                                                modifier
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            position:
                                                              'relative',
                                                          }}
                                                        >
                                                          {this.checkModifierAvailability(
                                                            subModifier
                                                          ) ? (
                                                            <span
                                                              className={classNames(
                                                                'subModifier-radio-container',
                                                                {
                                                                  checkbox:
                                                                    isMultiSelection &&
                                                                    modifier?.isMultiselect,
                                                                }
                                                              )}
                                                            >
                                                              <input
                                                                type={
                                                                  isMultiSelection &&
                                                                  modifier?.isMultiselect
                                                                    ? 'checkbox'
                                                                    : 'radio'
                                                                }
                                                                name={
                                                                  modifier.name
                                                                }
                                                                value={
                                                                  subModifier.name
                                                                }
                                                                className='subModifier-radio'
                                                                checked={
                                                                  subModifier.quantity >
                                                                    0 ||
                                                                  subModifier.isMultiselectSelected
                                                                    ? 'checked'
                                                                    : ''
                                                                }
                                                                onChange={(e) =>
                                                                  this.setModifier(
                                                                    e,
                                                                    mIndex,
                                                                    modifier,
                                                                    subModifier,
                                                                    activeModifiers,
                                                                    !(
                                                                      subModifier.quantity >
                                                                        0 ||
                                                                      subModifier.isMultiselectSelected
                                                                    )
                                                                  )
                                                                }
                                                              />
                                                              <span></span>
                                                            </span>
                                                          ) : (
                                                            <span
                                                              style={{
                                                                color: 'red',
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {
                                                                SOLD_OUT_ITEM_PRICE
                                                              }
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <div className='subModifer-container'>
                                                        <div className='gap-x-7 flex items-center'>
                                                          <div>
                                                            <p
                                                              style={{
                                                                fontWeight:
                                                                  '400',
                                                                fontSize:
                                                                  '1.95em',
                                                                padding:
                                                                  '0.5em 0',
                                                              }}
                                                              className='text-capitalize'
                                                            >
                                                              {subModifier.isVariant
                                                                ? `${subModifier.name} - ${subModifier._vName}`
                                                                : subModifier.name}
                                                            </p>
                                                          </div>
                                                          <div>
                                                            <p
                                                              style={{
                                                                fontWeight:
                                                                  '300',
                                                                fontSize:
                                                                  '1.95em',
                                                                padding:
                                                                  '0.5em 0',
                                                              }}
                                                              className='mr-2'
                                                            >
                                                              {this.getModifierPrice(
                                                                subModifier,
                                                                modifier
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className='relative mb-2.5'>
                                                          <ButtonGroup
                                                            _id={modifier._id}
                                                            disable={
                                                              !subModifier.isOptionAvailable
                                                            }
                                                            quantity={
                                                              subModifier.quantity
                                                            }
                                                            increaseBtnHandler={
                                                              this
                                                                .incrementItemQuantityModifiers
                                                            }
                                                            decreaseBtnHandler={
                                                              this
                                                                .decrementItemQuantityModifiers
                                                            }
                                                            operation='addToOrder'
                                                            clickedItem={
                                                              subModifier
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  )}
                                                  {modifier.isCustomiseSection ? (
                                                    <div
                                                      style={{
                                                        margin: '0 -20px',
                                                        borderBottom:
                                                          '1px solid #e1e5e5',
                                                      }}
                                                    >
                                                      <React.Fragment>
                                                        {itemInProgress &&
                                                        itemInProgress.selectedExtraIngredients &&
                                                        itemInProgress
                                                          .selectedExtraIngredients
                                                          .length > 0 ? (
                                                          <div
                                                            className={
                                                              itemInProgress.selectedIngredients &&
                                                              itemInProgress
                                                                .selectedIngredients
                                                                .length > 0
                                                                ? 'extBtn clickBtn'
                                                                : 'extBtn clickBtn full'
                                                            }
                                                            onClick={() =>
                                                              this.toggleExtraScreen()
                                                            }
                                                          >
                                                            <span>
                                                              {this.state
                                                                .addedExtraCount &&
                                                              this.state
                                                                .addedExtraCount >
                                                                0 ? (
                                                                <div className='activeCount'>
                                                                  {
                                                                    this.state
                                                                      .addedExtraCount
                                                                  }
                                                                </div>
                                                              ) : (
                                                                ''
                                                              )}
                                                              {this.state
                                                                .addedExtraCount &&
                                                              this.state
                                                                .addedExtraCount >
                                                                0
                                                                ? 'Edit '
                                                                : 'Add '}
                                                              Extras
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {itemInProgress &&
                                                        itemInProgress.selectedIngredients &&
                                                        itemInProgress
                                                          .selectedIngredients
                                                          .length > 0 ? (
                                                          <div
                                                            className={
                                                              itemInProgress.selectedExtraIngredients &&
                                                              itemInProgress
                                                                .selectedExtraIngredients
                                                                .length > 0
                                                                ? 'editIng clickBtn'
                                                                : 'editIng clickBtn full'
                                                            }
                                                            onClick={() =>
                                                              this.openIngredientSection()
                                                            }
                                                          >
                                                            <span>
                                                              Edit Ingredients
                                                              {this.state
                                                                .removedIngCount !==
                                                              0 ? (
                                                                <div className='activeCount'>
                                                                  {
                                                                    this.state
                                                                      .removedIngCount
                                                                  }
                                                                </div>
                                                              ) : (
                                                                ''
                                                              )}
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </React.Fragment>
                                                    </div>
                                                  ) : (
                                                    <React.Fragment />
                                                  )}
                                                </React.Fragment>
                                              );
                                            })}
                                        </div>
                                        <Function
                                          handleTriggerClick={this.handleTriggerClick(
                                            mIndex,
                                            activeModifiers
                                          )}
                                        />
                                      </Fragment>
                                    );
                                  })}
                              </Accordion>
                            </React.Fragment>
                          )}
                      </div>
                    ) : (
                      <div>
                        <span className='viewTitle'>Quantity</span>
                        <hr className='horizontal-ruler' />
                        {itemInProgress && itemInProgress.selectedSizes
                          ? orderBy(
                              itemInProgress.selectedSizes,
                              'displayOrder'
                            ).map((item, index) => {
                              const selectedVariants =
                                clickedItemVariants.filter((_v) =>
                                  item.variants
                                    .filter((iV) => iV.isSelected)
                                    .map((v) => v._id)
                                    .includes(_v._id)
                                );

                              const isVariantSelected =
                                selectedVariants.length ===
                                clickedItemVariants.length;
                              return clickedItemVariants.length === 0 ||
                                isVariantSelected ? (
                                <div className='qViewItem' key={index}>
                                  <div
                                    className={
                                      this.state.itemVariants &&
                                      this.state.itemVariants.length > 0 &&
                                      !item.isDisabled
                                        ? 'text-capitalize qCategory robMedium gFree'
                                        : 'text-capitalize qCategory robMedium'
                                    }
                                  >
                                    <span>{item.name}</span>
                                    <span>
                                      {this.state.itemVariants &&
                                        this.state.itemVariants.length > 0 &&
                                        !item.isDisabled &&
                                        ` ${this.state.itemVariants
                                          .filter((f) => f.isClicked)
                                          .map((m) => m.name)
                                          .join(', ')}`}
                                    </span>
                                  </div>
                                  {this.checkAvailability(item) ? (
                                    <div className='qPrice robLight redFont'>
                                      {SOLD_OUT_ITEM_PRICE}
                                    </div>
                                  ) : (
                                    <>
                                      <div
                                        className='qPrice robLight'
                                        style={{ marginTop: '1px' }}
                                      >
                                        {`${
                                          storeConfig.currency
                                        }${this.getSizePrice(item)}`}
                                      </div>
                                      <ButtonGroup
                                        _id={item._id}
                                        disable={item.isSoldOut}
                                        quantity={
                                          item.isSoldOut ? 0 : item.quantity
                                        }
                                        increaseBtnHandler={
                                          this.incrementItemQuantity
                                        }
                                        decreaseBtnHandler={
                                          this.decrementItemQuantity
                                        }
                                        operation='addToOrder'
                                        clickedItem={item}
                                      />
                                    </>
                                  )}
                                </div>
                              ) : null;
                            })
                          : ''}
                      </div>
                    )}
                  </React.Fragment>
                )}
                {(this.props.rightSideViewVisibilityIndex ===
                  VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW ||
                  this.props.rightSideViewVisibilityIndex ===
                    VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW ||
                  this.props.rightSideViewVisibilityIndex ===
                    VISIBILITY_INDEX_TYPE.VOUCHER_ORDER_VIEW) &&
                  itemInProgress && (
                    <React.Fragment>
                      {itemInProgress &&
                      itemInProgress.itemType &&
                      itemInProgress.itemType === 1 ? (
                        <React.Fragment>
                          <Accordion easing={'linear'} transitionTime={240}>
                            {orderBy(itemInProgress.modifiers, 'displayOrder')
                              .filter((m) => {
                                const subModifiersAvailabilityCount =
                                  m?.subModifiers
                                    ? m.subModifiers.filter(
                                        (f) =>
                                          f.isOptionAvailable &&
                                          f.isDisplayForSpecialOrHalfHalf
                                      ).length
                                    : 0;

                                return (
                                  m.isCustomiseSection ||
                                  !(
                                    (itemInProgress.baseModifierId === m._id &&
                                      subModifiersAvailabilityCount <= 1) ||
                                    subModifiersAvailabilityCount === 0
                                  )
                                );
                              })
                              .map((modifier, mIndex, activeModifiers) => {
                                return (
                                  <Fragment
                                    key={`${Math.random().toFixed(
                                      6
                                    )}-${mIndex}`}
                                  >
                                    <div
                                      data-trigger={
                                        modifier.sName
                                          ? `${modifier.sName}`
                                          : `${modifier.name}`
                                      }
                                      data-trigger-when-open={
                                        modifier.sName
                                          ? `${modifier.sName}`
                                          : modifier.isCustomiseSection
                                          ? `${modifier.name}`
                                          : `Select ${modifier.name}`
                                      }
                                      // data-trigger-disabled={modifier.isDisabled}
                                      data-trigger-isopen={modifier.isOpen}
                                      className='text-capitalize'
                                    >
                                      {orderBy(
                                        modifier.subModifiers,
                                        'displayOrder'
                                      )
                                        .filter(
                                          (f) =>
                                            f.isOptionAvailable &&
                                            f.isDisplayForSpecialOrHalfHalf
                                        )
                                        .map((subModifier, sIndex) => {
                                          return (
                                            <React.Fragment key={sIndex}>
                                              {!modifier.isCustomiseSection ? (
                                                <React.Fragment>
                                                  <div
                                                    key={sIndex}
                                                    className='subModifer-container'
                                                    onClick={(e) =>
                                                      this.setModifier(
                                                        e,
                                                        mIndex,
                                                        modifier,
                                                        subModifier,
                                                        activeModifiers
                                                      )
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          fontWeight: '400',
                                                          fontSize: '1.95em',
                                                          padding: '0.5em 0',
                                                        }}
                                                        className='text-capitalize'
                                                      >
                                                        {subModifier.isVariant
                                                          ? `${subModifier.name} - ${subModifier._vName}`
                                                          : subModifier.name}
                                                      </p>
                                                      <div>
                                                        <p
                                                          style={{
                                                            fontWeight: '300',
                                                            fontSize: '1.95em',
                                                            padding: '0.5em 0',
                                                            marginLeft: '30px',
                                                          }}
                                                        >
                                                          {this.getModifierPrice(
                                                            subModifier,
                                                            modifier
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      <span className='subModifier-radio-container'>
                                                        <input
                                                          type='radio'
                                                          name={modifier.name}
                                                          value={
                                                            subModifier.name
                                                          }
                                                          disabled={
                                                            this.checkModifierAvailability(
                                                              subModifier
                                                            ) ||
                                                            !subModifier.isDisplayForSpecialOrHalfHalf
                                                          }
                                                          className='subModifier-radio'
                                                          checked={
                                                            subModifier.quantity >
                                                            0
                                                              ? 'checked'
                                                              : ''
                                                          }
                                                          onChange={(e) =>
                                                            this.setModifier(
                                                              e,
                                                              mIndex,
                                                              modifier,
                                                              subModifier,
                                                              activeModifiers
                                                            )
                                                          }
                                                        />
                                                        <span></span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <div
                                                    key={sIndex}
                                                    className='subModifer-container'
                                                  >
                                                    <div className='gap-x-7 flex items-center'>
                                                      <div>
                                                        <p
                                                          style={{
                                                            fontWeight: '400',
                                                            fontSize: '1.95em',
                                                            padding: '0.5em 0',
                                                          }}
                                                          className='text-capitalize'
                                                        >
                                                          {subModifier.isVariant
                                                            ? `${subModifier.name} - ${subModifier._vName}`
                                                            : subModifier.name}
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p
                                                          style={{
                                                            fontWeight: '300',
                                                            fontSize: '1.95em',
                                                            padding: '0.5em 0',
                                                          }}
                                                          className='text-capitalize mr-2'
                                                        >
                                                          {this.getModifierPrice(
                                                            subModifier,
                                                            modifier
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className='relative mb-2.5'>
                                                      <ButtonGroup
                                                        _id={modifier._id}
                                                        disable={true}
                                                        quantity={
                                                          this.props
                                                            .rightSideViewVisibilityIndex ===
                                                          VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW
                                                            ? subModifier.quantity *
                                                              2
                                                            : subModifier.quantity
                                                        }
                                                        increaseBtnHandler={
                                                          this
                                                            .incrementItemQuantityModifiers
                                                        }
                                                        decreaseBtnHandler={
                                                          this
                                                            .decrementItemQuantityModifiers
                                                        }
                                                        operation='addToOrder'
                                                        clickedItem={
                                                          subModifier
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              )}
                                              {modifier.isCustomiseSection ? (
                                                <div
                                                  style={{
                                                    margin: '0 -26px',
                                                    borderBottom:
                                                      '1px solid #e1e5e5',
                                                  }}
                                                >
                                                  <React.Fragment>
                                                    {itemInProgress &&
                                                    itemInProgress.selectedExtraIngredients &&
                                                    itemInProgress
                                                      .selectedExtraIngredients
                                                      .length > 0 ? (
                                                      <div
                                                        className={
                                                          itemInProgress.selectedIngredients &&
                                                          itemInProgress
                                                            .selectedIngredients
                                                            .length > 0
                                                            ? 'extBtn clickBtn'
                                                            : 'extBtn clickBtn full'
                                                        }
                                                        onClick={() =>
                                                          this.toggleExtraScreen()
                                                        }
                                                      >
                                                        <span>
                                                          {this.state
                                                            .addedExtraCount &&
                                                          this.state
                                                            .addedExtraCount >
                                                            0 ? (
                                                            <div className='activeCount'>
                                                              {
                                                                this.state
                                                                  .addedExtraCount
                                                              }
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                          {this.state
                                                            .addedExtraCount &&
                                                          this.state
                                                            .addedExtraCount > 0
                                                            ? 'Edit '
                                                            : 'Add '}
                                                          Extras
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {itemInProgress &&
                                                    itemInProgress.selectedIngredients &&
                                                    itemInProgress
                                                      .selectedIngredients
                                                      .length > 0 ? (
                                                      <div
                                                        className={
                                                          itemInProgress.selectedExtraIngredients &&
                                                          itemInProgress
                                                            .selectedExtraIngredients
                                                            .length > 0
                                                            ? 'editIng clickBtn'
                                                            : 'editIng clickBtn full'
                                                        }
                                                        onClick={() =>
                                                          this.openIngredientSection()
                                                        }
                                                      >
                                                        <span>
                                                          Edit Ingredients
                                                          {this.state
                                                            .removedIngCount !==
                                                          0 ? (
                                                            <div className='activeCount'>
                                                              {
                                                                this.state
                                                                  .removedIngCount
                                                              }
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </React.Fragment>
                                                </div>
                                              ) : (
                                                <React.Fragment />
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                    </div>
                                    <Function
                                      handleTriggerClick={this.handleTriggerClick(
                                        mIndex,
                                        activeModifiers
                                      )}
                                    />
                                  </Fragment>
                                );
                              })}
                          </Accordion>
                        </React.Fragment>
                      ) : (
                        itemInProgress.isDisplayForSpecialOrHalfHalf && (
                          <div>
                            <span className='viewTitle'>Sizes</span>
                            <hr />
                            {itemInProgress && itemInProgress.selectedSizes
                              ? itemInProgress.selectedSizes.map(
                                  (item, index) =>
                                    item.isDisplayForSpecialOrHalfHalf && (
                                      <div className='qViewItem' key={index}>
                                        <div
                                          className={
                                            this.state.itemVariants &&
                                            this.state.itemVariants.length >
                                              0 &&
                                            !item.isDisabled
                                              ? 'qCategory robMedium gFree'
                                              : 'qCategory robMedium'
                                          }
                                        >
                                          {item.name}
                                          {this.state.itemVariants &&
                                            this.state.itemVariants.length >
                                              0 &&
                                            !item.isDisabled &&
                                            ` ${this.state.itemVariants
                                              .filter((f) => f.isClicked)
                                              .map((m) => m.name)
                                              .join(',')}`}
                                        </div>
                                        <div className='qPrice robLight'>
                                          {`${
                                            storeConfig.currency
                                          }${this.getSizePrice(item)}`}
                                        </div>
                                        <ButtonGroup
                                          disable={
                                            item.isSoldOut || item.isDisabled
                                          }
                                          quantity={
                                            item.isSoldOut || item.isDisabled
                                              ? 0
                                              : item.quantity
                                          }
                                          maxQuantity='1'
                                          increaseBtnHandler={
                                            this.incrementItemQuantity
                                          }
                                          decreaseBtnHandler={
                                            this.decrementItemQuantity
                                          }
                                          operation='addToOrder'
                                          clickedItem={item}
                                        />
                                      </div>
                                    )
                                )
                              : ''}
                          </div>
                        )
                      )}
                    </React.Fragment>
                  )}
                {itemInProgress &&
                  ((itemInProgress.selectedExtraIngredients &&
                    itemInProgress.selectedExtraIngredients.length > 0) ||
                    (itemInProgress.selectedIngredients &&
                      itemInProgress.selectedIngredients.length > 0)) &&
                  itemInProgress.itemType !== 1 && (
                    <div className='customiseView'>
                      <span className='viewTitle' style={{ display: 'none' }}>
                        Customise
                      </span>

                      <hr style={{ display: 'none' }} />

                      {itemInProgress?.selectedExtraIngredients?.length > 0 && (
                        <div
                          className={
                            itemInProgress.selectedIngredients &&
                            itemInProgress.selectedIngredients.length > 0
                              ? 'extBtn clickBtn'
                              : 'extBtn clickBtn full'
                          }
                          onClick={() => this.toggleExtraScreen()}
                        >
                          <span>
                            {this.state.addedExtraCount &&
                            this.state.addedExtraCount > 0 ? (
                              <div className='activeCount'>
                                {this.state.addedExtraCount}
                              </div>
                            ) : (
                              ''
                            )}
                            {this.state.addedExtraCount &&
                            this.state.addedExtraCount > 0
                              ? 'Edit '
                              : 'Add '}
                            Extras
                          </span>
                        </div>
                      )}
                      {itemInProgress?.selectedIngredients?.length > 0 && (
                        <div
                          className={
                            itemInProgress.selectedExtraIngredients &&
                            itemInProgress.selectedExtraIngredients.length > 0
                              ? 'editIng clickBtn'
                              : 'editIng clickBtn full'
                          }
                          onClick={() => this.openIngredientSection()}
                        >
                          <span>
                            Edit Ingredients
                            {this.state.removedIngCount !== 0 ? (
                              <div className='activeCount'>
                                {this.state.removedIngCount}
                              </div>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      )}
                      {this.props.rightSideViewVisibilityIndex <
                        VISIBILITY_INDEX_TYPE.HALF_HALF_VIEW &&
                        itemInProgress &&
                        (!itemInProgress.itemType ||
                          itemInProgress.itemType === 0) &&
                        this.state.itemVariants.length > 0 && (
                          <div>
                            {this.state.itemVariants.map((v) => (
                              <div key={v._id} className='glutenFree hide'>
                                {v.name}
                                <span
                                  className={
                                    v.isClicked
                                      ? 'iconWala thickcon-Checkmark active'
                                      : 'iconWala thickcon-Checkmark'
                                  }
                                  onClick={() => this.toggleVariant(v)}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  )}
                <div
                  className={`proceedWrap ${
                    this.props.openedFrom === 'special'
                  }`}
                >
                  <div className='proceedOrder'>
                    <div className='proceedBtn' onClick={this.addToOrder}>
                      Add to Order&nbsp;
                      {this.props.rightSideViewVisibilityIndex !==
                        VISIBILITY_INDEX_TYPE.VOUCHER_ORDER_VIEW && (
                        <>
                          -{' '}
                          {parseFloat(itemPrice) > 0 && (
                            <span className=' hidden-xs hidden-sm'>
                              {storeConfig.currency}
                              {itemPrice.toFixed(2)}
                            </span>
                          )}
                          {this.props.rightSideViewVisibilityIndex !==
                            VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW && (
                            <span className='visible-xs visible-sm'>
                              {storeConfig.currency}
                              {itemPrice.toFixed(2)}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Extras Section Start*/}
        <AddExtrasSection
          toggleTransition={this.state.toggleTransition}
          toggleExtrasSection={this.state.toggleExtrasSection}
          isExtraChanged={this.state.isExtraChanged}
          ingredientCategories={this.state.ingredientCategories}
          isTabTransition={this.state.isTabTransition}
          popularIngredients={this.state.popularIngredients}
          storeConfig={storeConfig}
          extraPrice={this.state.extraPrice}
          closeExtraSection={this.closeExtraSection}
          getExtraPrice={this.getExtraPrice}
          addExtasInItem={this.addExtasInItem}
          decrementExtraItem={this.decrementExtraItem}
          incrementExtrasItem={this.incrementExtrasItem}
          sBaseSubModifier={this.sBaseSubModifier}
          sSize={this.sSize}
          itemType={this.state?.itemInProgress?.itemType}
          openedFrom={this.props.openedFrom}
        />
        {/* Extras Section Ended*/}

        {/* Edit Ingredients Section Starts*/}
        <div
          className={
            this.state.toggleIngredientsSection
              ? 'extraScreen editIngWrap active'
              : 'extraScreen editIngWrap'
          }
        >
          <CloseButtonCWUI
            onClick={() => this.closeIngredientSection()}
            extendedClassName='ml-5 mt-3.5'
          />
          <h2>Edit Ingredients</h2>
          <div
            className={
              this.state.isIngredientsChanged ? 'remIng scroll' : 'remIng'
            }
          >
            <ul className='editList'>
              {itemInProgress && itemInProgress.selectedIngredients
                ? itemInProgress.selectedIngredients.map((item, index) => (
                    <Ingredients
                      key={index}
                      item={item}
                      addRemoveIngredient={this.addRemoveIngredient}
                      removed={item.inOrder}
                    />
                  ))
                : ''}
            </ul>
          </div>
          <div
            className={
              this.state.isIngredientsChanged
                ? 'remButtonWrap active'
                : 'remButtonWrap'
            }
            onClick={() => this.addIngredientToItem()}
          >
            <span>Save ingredients</span>
          </div>
        </div>
        {/* Edit Ingredients Section Ends*/}
      </div>
    );
  }
}

ItemView.propTypes = {
  addToOrder: PropType.any,
  itemInProgress: PropType.any,
  openedItemId: PropType.any,
  rightSideViewVisibilityIndex: PropType.any,
};

function mapStateToProps(state) {
  return {
    isMinifiedVersion: state.homeReducer.isMinifiedVersion,
    soldOutMenuItems: state.homeReducer.soldOutMenuItems,
    storeConfig: selectStoreConfig(state),
    halfHalfSetup: state.layoutReducer.halfHalfSetup,
  };
}

export default connect(mapStateToProps)(ItemView);
