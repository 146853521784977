const isURL = (str) => {
  const urlPattern = /^(https?|ftp):\/\//i;
  return urlPattern.test(str?.trim());
};

const getImage = (image) => {
  if (!image) {
    return '';
  }
  if (isURL(image)) {
    return image;
  } else {
    const storageBaseUrl =
      window.storeConfig?.storageBaseUrl ||
      window.storeConfigInitial?.storageBaseUrl;
    return `${storageBaseUrl}${image}`;
  }
};

export default getImage;
