import {
  ItemSearchViewUI,
  ModalCWShellUI,
} from '@next-order/next-order-components';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import { isMobile } from '../../helpers/isMobile';
import { eventNames } from 'configuration/eventNames';
import { MenuContext } from 'contexts/MenuProvider';
import getImage from 'helpers/getImage';

const glutenFreeVariants = [
  'gluten',
  'gluten free',
  'gluten-free',
  'glutenfree',
  'gf',
];
// const dairyFreeVariants = ['dairy free', 'dairy-free', 'dairyfree', 'df'];
// const veganVariants = ['vegan', 'vegitarian', 'no meat', 'veg', 'veggie'];

export default function SearchModal({
  searchCategoriesList,
  onClickItemHandler,
}) {
  const { searchModalOpen, setSearchModalOpen } = useContext(MenuContext);

  const [search, setSearch] = useState('');

  const allData = useMemo(() => {
    if (!search) return [];
    const searchLowerCase = search.toLowerCase();

    return searchCategoriesList
      .map((category) => ({
        name: category.name,
        data:
          // condition 1: if category name matches search, return all items in category
          category.name.toLowerCase().includes(searchLowerCase)
            ? category.data
            : // condition 2: else return items that match search
              category.data.filter(
                (menuItem) =>
                  // condition 3: check if search matches menuItem name
                  menuItem.name.toLowerCase().includes(searchLowerCase) ||
                  // condition 4: check if search matches menuItem size name
                  menuItem.selectedSizes?.some(
                    (size) =>
                      size.name.toLowerCase().includes(searchLowerCase) ||
                      // condition 5: check if search is for gluten free, dairy free, or vegan
                      (size.isGlutenFree &&
                        glutenFreeVariants.includes(searchLowerCase)) ||
                      // condition 6: check if search matches menuItem size variant name
                      size.variants?.some((variant) =>
                        variant.name.toLowerCase().includes(searchLowerCase)
                      )
                  )
              ),
      }))
      .filter((category) => category.data.length > 0);
  }, [searchCategoriesList, search]);

  const onClose = () => {
    setSearch('');
    setSearchModalOpen(false);
  };

  const onChooseResultItem = (menuItem) => {
    onClickItemHandler(
      menuItem.itemObjType,
      menuItem,
      eventNames.SEARCH_MENU_ITEM_OPENED
    );
    onClose();
  };

  return (
    <ModalCWShellUI
      maxWidth={460}
      open={searchModalOpen}
      onOpenChange={onClose}
      maxHeight='full'
      zIndex={100}
      extendedClassName={classNames(
        '-sm:!rounded-none -sm:!max-w-full -sm:!h-full',
        {
          'sm:!rounded-none sm:!max-w-full sm:!h-full sm:!max-h-full sm:!my-0 sm:!w-full':
            isMobile(),
          ' sm:!bg-transparent': !search && isMobile(),
          ' !bg-transparent': !search,
        }
      )}
    >
      <VisualViewport className='flex h-full sm:overflow-hidden'>
        <ModalCWShellUI.CloseButton onClick={onClose} />
        <ItemSearchViewUI
          autoFocus={true}
          autoComplete='off'
          extendedClassName={classNames('pt-18 sm:pt-13.5', {
            ' sm:!bg-transparent sm:h-full': !search && isMobile(),
          })}
          extendedHeaderClassName={classNames({
            ' sm:hidden': !search && isMobile(),
          })}
          s={search}
          placeholder='Search Menu'
          onChangeSearch={(e) => setSearch(e.target.value)}
          onClickResetSearch={(e) => setSearch('')}
        >
          {search && allData?.length > 0 ? (
            allData.map((category, index) => (
              <ItemSearchViewUI.ResultGroup title={category.name} key={index}>
                {category.data.map((menuItem, i) => (
                  <ItemSearchViewUI.ResultItem
                    key={i + index + 100000}
                    imgSrc={getImage(menuItem.mediaPath || menuItem.urlS3)}
                    title={menuItem.name}
                    onClick={() => onChooseResultItem(menuItem)}
                  />
                ))}
              </ItemSearchViewUI.ResultGroup>
            ))
          ) : search ? (
            <ItemSearchViewUI.NoResult>Not Found</ItemSearchViewUI.NoResult>
          ) : null}
        </ItemSearchViewUI>
      </VisualViewport>
    </ModalCWShellUI>
  );
}

const VisualViewport = ({
  as: Element = 'div',
  children,
  style = {},
  ...props
}) => {
  const ref = useRef(null);

  const [viewport, setViewport] = useState({
    maxHeight: '100%',
    maxWidth: '100%',
  });

  useEffect(() => {
    const updateViewport = debounce(100, () => {
      setViewport({
        maxHeight: window.visualViewport.height,
        maxWidth: window.visualViewport.width,
      });

      window.scrollTo(0, ref.current.offsetTop);
    });

    if (
      typeof window !== 'undefined' &&
      typeof window.visualViewport !== 'undefined'
    ) {
      updateViewport();

      window.visualViewport.addEventListener('resize', updateViewport);

      return () =>
        window.visualViewport.removeEventListener('resize', updateViewport);
    }
  }, []);

  return (
    <Element ref={ref} style={{ ...style, ...viewport }} {...props}>
      {children}
    </Element>
  );
};
