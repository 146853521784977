import {
  CustomerCategoryTitleUI,
  GridUI,
} from '@next-order/next-order-components';
import HalfHalfOrSpecialMenuItemsViewContainer from 'components/NewMenuItem/HalfHalfOrSpecialMenuItemsViewContainer';
import {
  CATEGORY_DISPLAY_TYPE,
  MENU_ITEM_OBJ_TYPE,
  SOLD_OUT_ITEM_PRICE,
} from 'helpers/enums';
import getMenuItemIngredients from 'helpers/getMenuItemIngredients';
import getPriceForDisplay from 'helpers/getPriceForDisplay';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SearchModal from './SearchModal';
import MenuItemsLoadingSkeleton from './MenuItemsLoadingSkeleton';
import getImage from 'helpers/getImage';

export default function RenderFilterAppliedSpecialMenuItems({
  filteredCategories,
  halfHalfSetup,
  onClickItemHandler,
  spSubModifiers,
  currency,
}) {
  const {
    soldOutMenuItems,
    specialFilter: { isSpecialFilterApplied, spMenuSizes, spMenuCategories },
    orderSetup: { enableHalfHalfInSpecials },
    currentOrder,
  } = useSelector((state) => state.homeReducer);

  const categoriesList = useMemo(() => {
    return filteredCategories?.map((filterSPCat) => {
      let data = filterSPCat?.filteredMenuItems?.map((menuItem) => ({
        ...menuItem,
        itemObjType: menuItem.itemType,
        unitPrice: getPriceForDisplay(
          menuItem,
          isSpecialFilterApplied,
          soldOutMenuItems,
          spMenuSizes,
          { orderType: currentOrder?.orderType }
        ),
      }));

      let matchingHalfCategories = [];

      if (enableHalfHalfInSpecials !== false && halfHalfSetup) {
        matchingHalfCategories = (halfHalfSetup.halfHalfs ?? []).filter(
          (item) => item?.categories?.some((c) => c._id === filterSPCat._id)
        );
      }

      if (matchingHalfCategories.length > 0) {
        const halfHalfImage = getImage(
          halfHalfSetup.halfHalfPlaceholderPath ||
            halfHalfSetup.halfHalfPlaceholderImage ||
            '/assets/placeholder.jpg'
        );
        let obj = {};
        obj.itemType = MENU_ITEM_OBJ_TYPE.halfhalf;
        obj.itemObjType = MENU_ITEM_OBJ_TYPE.halfhalf;
        obj.item = matchingHalfCategories[0];
        obj.displayOrder = -1;
        obj.totalRevenue = 1000000000000;
        obj.lazyLoad = false;
        obj.urlS3 = halfHalfImage;
        obj.key = `halhalk23set`;
        obj.showName = true;
        obj.name = `HALF / HALF`;

        const activeSizes = spMenuSizes?.filter(({ isActive }) => isActive);
        const activeSubModifiers = (spSubModifiers || []).filter(
          ({ isActive }) => isActive
        );

        const selectedSizes = obj?.item?.selectedSizes?.filter(
          ({ isActive, _id }) =>
            isActive && activeSizes?.findIndex((s) => s._id === _id) !== -1
        );

        const selectedModifiers = (obj?.item?.selectedModifiers ?? []).filter(
          ({ isActive, _id }) =>
            isActive &&
            activeSubModifiers?.findIndex((s) => s._id === _id) !== -1
        );
        let defaultSizes = (obj?.item?.defaultSizes ?? []).filter(
          ({ isActive, _id }) =>
            isActive &&
            (activeSizes?.findIndex((s) => s._id === _id) !== -1 ||
              activeSubModifiers?.findIndex((s) => s._id === _id) !== -1)
        );

        if (defaultSizes.length <= 0) {
          defaultSizes = [...selectedSizes, ...selectedModifiers];
        }

        if (defaultSizes.length) {
          data = [
            {
              ...obj,
              ...obj.item,
              selectedModifiers,
              selectedSizes,
              defaultSizes,
            },
            ...data,
          ];
        }
      }

      data = data?.sort((a, b) => a?.displayOrder - b?.displayOrder);

      return {
        data,
        displayType: CATEGORY_DISPLAY_TYPE.GRID,
        name: filterSPCat.name,
        _id: filterSPCat._id,
      };
    });
  }, [
    currentOrder?.orderType,
    filteredCategories,
    halfHalfSetup,
    spMenuSizes,
    spSubModifiers,
    isSpecialFilterApplied,
    soldOutMenuItems,
    enableHalfHalfInSpecials,
  ]);

  const searchCategoriesList = useMemo(() => {
    return (categoriesList ?? [])
      .map((category) => ({
        name: category?.name,
        data: (category?.data ?? []).filter(
          (item) => item.itemPrice !== SOLD_OUT_ITEM_PRICE
        ),
      }))
      ?.filter((category) => category?.data?.length > 0);
  }, [categoriesList]);

  return (
    <>
      {categoriesList?.length > 0 && (
        <>
          <CustomerCategoryTitleUI>
            Select{' '}
            {spMenuCategories
              ?.filter((f) => f.isActive)
              .map((f) => f.name)
              .join(' or ')}
          </CustomerCategoryTitleUI>

          <GridUI align='start' gap='none' colsTemplate={`cw-grid`}>
            {categoriesList?.map((category) =>
              category?.data?.map((menuItem) => (
                <HalfHalfOrSpecialMenuItemsViewContainer
                  key={`sp-${menuItem._id}`}
                  productName={menuItem.name}
                  bgImgSrc={getImage(
                    menuItem.mediaPath || menuItem.urlS3 || menuItem.url
                  )}
                  ingredients={getMenuItemIngredients(menuItem.description)}
                  menuItem={menuItem}
                  onClickItemHandler={onClickItemHandler}
                  currency={currency}
                />
              ))
            )}
          </GridUI>
        </>
      )}
      {categoriesList.length <= 0 && (
        <MenuItemsLoadingSkeleton noOfItems={12} />
      )}
      <SearchModal
        searchCategoriesList={searchCategoriesList}
        onClickItemHandler={onClickItemHandler}
      />
    </>
  );
}
