import { BREAKPOINT_VALUES } from 'helpers/enums';
import getImage from 'helpers/getImage';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const MenuContext = createContext({
  selectedMenuItems: [],
  mappedSelectedMenuItemQuantities: {},
  selectedMenuItemsLength: 0,
  searchModalOpen: false,
  breakPoint: BREAKPOINT_VALUES.DESKTOP,
  setSearchModalOpen: () => {},
});

export default function MenuProvider({ children }) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [breakPoint, setBreakPoint] = useState(BREAKPOINT_VALUES.DESKTOP);

  const { currentOrder } = useSelector((state) => state.homeReducer);

  const selectedMenuItems = useMemo(() => {
    const selectedOrderMenuItems = JSON.parse(
      JSON.stringify(currentOrder.menuItems ?? [])
    );
    const items = [];

    selectedOrderMenuItems?.forEach((item) => {
      if (item.isHalf) {
        const betterHalfs = selectedOrderMenuItems.filter(
          (obj) =>
            obj.halfIndex === item.halfIndex && obj.whichHalf > item.whichHalf
        );
        /* if (!betterHalfs.length) {
            betterHalfs = selectedOrder.menuItems.filter((obj) => {
              return obj.halfIndex === item.halfIndex && obj._id === item._id;
            });
          } */
        if (betterHalfs && betterHalfs.length > 0) {
          item.urlHalf = getImage(
            betterHalfs[0].mediaPath || betterHalfs[0].urlS3
          );
          item._idHalf = betterHalfs[0]._id;
          item.nameHalf = betterHalfs[0].name;
          item.itemTypeHalf = betterHalfs[0].itemType;
          item.baseModifierIdHalf = betterHalfs[0].baseModifierId;
          item.selectedSizesHalf = betterHalfs[0].selectedSizes;
          item.selectedModifiersHalf = betterHalfs[0].selectedModifiers;
          item.removedIngredientsHalf = betterHalfs[0].removedIngredients;
          item.selectedIngredientsHalf = betterHalfs[0].selectedIngredients;
          item.selectedExtraIngredientsHalf =
            betterHalfs[0].selectedExtraIngredients;
          items.push(item);
        }
      } else {
        items.push(item);
      }
    });

    /*

     items.forEach((menuItem) => {
      if (menuItemSizes?.length || menuItemSubModifiers?.length) {
        const newMenuItemSizes = menuItemSizes.filter(
          (f) =>
            f.orderType === currentOrder.orderType &&
            f.menuItemId === menuItem._id
        );
        const newMenuItemSubModifiers = menuItemSubModifiers.filter(
          (f) =>
            f.orderType === currentOrder.orderType &&
            f.menuItemId === menuItem._id
        );

        // Mutate the menuItem
        getMenuItemForInstore(
          menuItem,
          newMenuItemSizes,
          newMenuItemSubModifiers
        );
      }
    });
    */

    return items;
  }, [currentOrder.menuItems]);

  // We'll use mappedSelectedMenuItemQuantities for display selected menuitems quantity
  // This way, we can prevent looping through selectedMenuItems everytime
  const mappedSelectedMenuItemQuantities = useMemo(() => {
    const mappedItems = {};
    currentOrder.menuItems?.forEach((item) => {
      if (!currentOrder.specials?.some((s) => s._id === item.specialId)) {
        mappedItems[item._id] = item.quantity;
      }
    });
    currentOrder.specials?.forEach((item) => {
      mappedItems[item._id] = (mappedItems[item._id] ?? 0) + 1;
    });

    return mappedItems;
  }, [currentOrder.menuItems, currentOrder.specials]);

  useEffect(() => {
    // This way we can handle window resize without causing multiple re-renders
    const handleWindowSizeChange = () => {
      let breakpointName = BREAKPOINT_VALUES.DESKTOP;
      if (window.innerWidth < BREAKPOINT_VALUES.MINI) {
        breakpointName = BREAKPOINT_VALUES.MINI;
      } else if (window.innerWidth < BREAKPOINT_VALUES.MOBILE) {
        breakpointName = BREAKPOINT_VALUES.MOBILE;
      } else if (window.innerWidth < BREAKPOINT_VALUES.TABLET) {
        breakpointName = BREAKPOINT_VALUES.TABLET;
      } else if (window.innerWidth < BREAKPOINT_VALUES.DESKTOP) {
        breakpointName = BREAKPOINT_VALUES.DESKTOP;
      } else {
        breakpointName = BREAKPOINT_VALUES.BIG_SCREEN;
      }

      setBreakPoint(breakpointName);
    };
    window.addEventListener('resize', handleWindowSizeChange, true);

    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange, true);
    };
  }, []);

  return (
    <MenuContext.Provider
      value={{
        selectedMenuItems,
        mappedSelectedMenuItemQuantities,
        selectedMenuItemsLength: selectedMenuItems.length,
        searchModalOpen,
        breakPoint,
        setSearchModalOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
