import getImage from 'helpers/getImage';
import ItemView from '../../components/ItemView';

// .example1
export default function OrderView({
  rightSideViewVisibilityIndex,
  itemInProgress,
  ingredientCategories,
  orderItem,
  openedItemId,
  closeOrder,
  addToOrder,

  sidebarAnimationClass,
}) {
  const renderIt = () => {
    const image = getImage(orderItem?.mediaPath || orderItem?.urlS3);
    return (
      <ItemView
        ingredientCategories={ingredientCategories}
        orderItem={orderItem}
        name={orderItem && orderItem.name ? orderItem.name : ''}
        imageUrl={image}
        description={
          orderItem && orderItem.description ? orderItem.description : ''
        }
        itemInProgress={itemInProgress}
        closeItem={closeOrder}
        addToOrder={addToOrder}
        rightSideViewVisibilityIndex={rightSideViewVisibilityIndex}
        openedFrom={'orderView'}
      />
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      {!!openedItemId && renderIt()}
    </div>
  );
}
